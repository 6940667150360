import { CommonDialogs, YesNoDialogProps } from "@mcleod/common";
import {
    Button,
    ButtonVariant,
    Component,
    ComponentTypes, Container,
    DataSource,
    DataSourceProps,
    DesignableObject,
    DesignerInterface,
    Dialog,
    KeyEvent, KeyHandler,
    Label,
    Layout,
    Panel,
    SlideoutDecorator,
    Snackbar,
    Splitter,
    TabCloseEvent,
    Table,
    TableCell,
    TableColumn,
    Tabset, Textbox, Tree,
    TreeNode,
    deserializeComponents,
    serializeComponents
} from "@mcleod/components";
import { ReflectiveDialogs } from "@mcleod/components/src/base/ReflectiveDialogs";
import { Alignment, Api, AuthType, DOMUtil, DynamicLoader, HorizontalAlignment, Keys, MetadataField, Model, ModelRow, Navigation, NavigationEvent, StringUtil, getLogger, isRunningInIDE, setClassIncluded } from "@mcleod/core";
import { ExtendedDataType } from "@mcleod/core/src/ApiMetadata";
import { CodeEditor } from "../common/CodeEditor";
import { DesignerDataSource } from "./DesignerDataSource";
import { DesignerTabDescriptor } from "./DesignerTabDescriptor";
import { DesignerTool } from "./DesignerTool";
import { DesignerToolsPanel } from "./DesignerToolsPanel";
import { DragAndDropHandler } from "./DragAndDropHandler";
import { PanelOpenLayout } from "./PanelOpenLayout";
import { PropertiesTable } from "./PropertiesTable";
import { doDesignerAction, getDesignerRedoAction, getDesignerUndoAction, redoDesignerAction, undoDesignerAction } from "./UIDesignerActionHistory";
import { getDesignerKeyListeners } from "./UIDesignerKeyHandlers";
import { designerApplyChangeToSelectedComponents, designerClasses, designerHandleComponentSelection } from "./UIDesignerUtil";
import { UILayoutVersions } from "./UILayoutVersions";
import { ActionAddComponent } from "./actions/ActionAddComponent";
import { ActionAddTableColumn } from "./actions/ActionAddTableColumn";
import { ActionChangeComponentProperty } from "./actions/ActionChangeComponentProperty";
import { ActionDeleteComponent } from "./actions/ActionDeleteComponent";
import { ActionInsertIntoNewPanel } from "./actions/ActionInsertIntoNewPanel";
import { DesignerTab } from "./actions/DesignerTab";

const log = getLogger("designer.ui.AbstractUIDesigner");

export interface DesignerContainer extends Container {
    acceptsTool(tool: DesignerTool): boolean;
}

export interface LocalStorageKeys {
    openTabs: string;
    selectedTab: string;
}

export abstract class AbstractUIDesigner extends Layout implements DesignerInterface {
    tableProps: PropertiesTable;
    copiedComponents: Component[];
    inputTableSearch: Textbox;
    inputFieldSearch: Textbox;
    tabset: Tabset;
    buttonViewCode: Button;
    buttonViewLayout: Button;
    buttonRun: Button;
    buttonOpen: Button;
    buttonSave: Button;
    buttonManageVersions: Button;
    buttonSaveNewVersion: Button;
    buttonNew: Button;
    buttonUndo: Button;
    buttonRedo: Button;
    toolsPanel: DesignerToolsPanel;
    selectedComponents: Component[];
    selectedLayoutComponents: Component[];
    finishedLoading: boolean;
    open: string; // passed as props from URL
    openBase: string; // passed as props from URL
    lastChangedProp: string;
    splitterProps: Splitter;
    splitterTools: Splitter;
    dragDropHandler: DragAndDropHandler;
    notifyActionHistory = true;
    private _layoutEndpointPath: string;
    panelPropsHeader: Panel;
    panelProps: Panel;

    abstract designerTools: DesignerTool[];
    abstract localStorageKeys: LocalStorageKeys;

    constructor(props?) {
        super({ auth: AuthType.LME, fillHeight: true, scrollX: true, needsServerLayout: false, ...props });
        this._layoutEndpointPath = props.layoutEndpointPath ?? "layout";
        this.dragDropHandler = new DragAndDropHandler(this);
        this.tableProps = new PropertiesTable(this, { id: "tableProps" });
        log.debug("Render designer");
        this.copiedComponents = [];
        this.setProps({ auth: [AuthType.ANY], fillRow: true, fillHeight: true, padding: 0 });
        this.toolsPanel = new DesignerToolsPanel(this, { fillRow: true });
        this.tabset = new Tabset({ fillRow: true, fillHeight: true, rowBreak: false, allowStyleChange: false });
        this.splitterTools = new Splitter({ id: "splitterTools", position: 240, fillRow: true, fillHeight: true, expandButtonsVisible: false });
        this.splitterProps = new Splitter({ id: "splitterProps", position: "80%", fillRow: true, fillHeight: true, expandButtonsVisible: false });
        this.splitterTools.add(this.toolsPanel);
        this.splitterTools.add(this.splitterProps);
        this.add(this.splitterTools);
        this.splitterProps.add(this.tabset);
        this.addTabsetToolbar({ variant: ButtonVariant.round, color: "subtle.darker", dropped: false });
        this.panelProps = new Panel({ fillRow: true, height: "100%", padding: 0, borderLeftWidth: 1, borderLeftColor: "strokeSecondary" });
        this.panelPropsHeader = new Panel({ backgroundColor: "primary", padding: 0, align: HorizontalAlignment.CENTER, fillRow: true });
        this.panelPropsHeader.add(new Label({ caption: "Properties", color: "primary.reverse", align: HorizontalAlignment.CENTER, fillRow: true, fillHeight: true, rowBreak: false }))
        this.panelProps.add(this.panelPropsHeader);
        this.panelProps.add(this.tableProps);
        this.splitterProps.add(this.panelProps);

        Navigation.addNavigationListener({
            onNavigate: async (event: NavigationEvent) => {
                const isNewTab = event.navOptions != null && event.navOptions.newTab === true;
                if (this.isModified() && !isNewTab) {
                    if (event.navOptions?.hardRefresh || !await ReflectiveDialogs.showYesNo("Are you sure you want to leave this page without saving?", "Close Without Saving?"))
                        event.preventDefault();
                }
            }
        });

        this.tabset.addAfterTabSelectionListener(event => this.tabChanged(event.newSelection));
        this.tabset.addBeforeTabCloseListener(event => this.tabClosed(event));
        this.selectedComponents = [];
        const open = this.getLastOpen();
        if (open.length === 0 && this.open == null)
            this.openTab(null, true, true);
        else {
            const lastSel = this.getLastSelected();
            for (let i = 0; i < open.length; i++) {
                const openDescriptor = open[i];
                this.openTabFromDescriptor(openDescriptor, openDescriptor.equals(lastSel));
            }
        }
        this.finishedLoading = true;
        if (this.open != null)
            this.openTab(this.open, this.openBase === "true", true);
    }

    public get layoutEndpointPath(): string {
        return this._layoutEndpointPath;
    }

    public set layoutEndpointPath(value: string) {
        this._layoutEndpointPath = value;
    }

    filterProps(props: any, selectedComponent: Component): void {
    }

    disablePropertyEditors(prop: any, editorComponents: Component[], selectedComponent: Component): void {
    }

    addTabsetToolbar(toolsProps: any) {
        this.buttonViewCode = new Button({ ...toolsProps, tooltip: "View code for this layout", imageName: "curlyBrackets" });
        this.buttonViewCode.addClickListener(event => this.showCode());
        this.buttonViewLayout = new Button({ ...toolsProps, tooltip: "View raw layout file", imageName: "codeTags" });
        this.buttonViewLayout.addClickListener(event => this.showJson());
        this.buttonRun = new Button({ ...toolsProps, tooltip: "Preview this layout", imageName: "run" });
        this.buttonRun.addClickListener(event => this.showTest());
        this.buttonOpen = new Button({ ...toolsProps, tooltip: "Open a layout for editing", imageName: "folder" });
        this.buttonOpen.addClickListener(event => this.showOpen());
        this.buttonSave = new Button({ ...toolsProps, tooltip: "Save this layout", imageName: "disk" });
        this.buttonSave.addClickListener(event => this.showSave());
        this.buttonManageVersions = new Button({ ...toolsProps, tooltip: "Manage the versions of this layout", imageName: "formPencil" });
        this.buttonManageVersions.addClickListener(event => this.showManageVersions())
        this.buttonSaveNewVersion = new Button({ ...toolsProps, tooltip: "Save a new copy of this custom layout", imageName: "duplicateDisk" });
        this.buttonSaveNewVersion.addClickListener(event => this.saveCustomLayout(this.getActiveTab(), true));
        this.buttonNew = new Button({ ...toolsProps, tooltip: "Create a new layout", imageName: "add" });
        this.buttonNew.addClickListener(event => this.addNewTab());
        this.buttonUndo = new Button({ ...toolsProps, tooltip: "Undo change", enabled: false, imageName: "undo" });
        this.buttonUndo.addClickListener(event => undoDesignerAction(this));
        this.buttonRedo = new Button({ ...toolsProps, tooltip: "Redo change", enabled: false, imageName: "redo" });
        this.buttonRedo.addClickListener(event => redoDesignerAction(this));
        this.tabset.tools = this.tabsetTools;
    }

    get tabsetTools(): Button[] {
        return [
            this.buttonViewCode,
            this.buttonViewLayout,
            this.buttonRun,
            this.buttonOpen,
            this.buttonSave,
            this.buttonNew,
            this.buttonUndo,
            this.buttonRedo
        ]
    }

    get firstSelected(): Component {
        if (this.selectedComponents.length === 0)
            return null;
        return this.selectedComponents[0];
    }

    get firstSelectedLayoutComponent(): Component {
        return this.selectedLayoutComponents?.[0];
    }

    // function called by router that can affect the properties of the router
    getRouterProps() {
        return { padding: 0 };
    }


    async getResourceInfo(): Promise<ModelRow> {
        const response = await Model.search("resource/info", {
            resource_name: this.getActiveTab().path + ".layout",
            resource_type: "layouts"
        });
        return response.getSingleModelRow();
    }

    async getCodeFileName(): Promise<string> {
        return (await this.getResourceInfo()).get("typescript_path");
    }

    addEventHandlerFunction(component: DesignableObject, eventName: string): void {
        const prop = component.getPropertyDefinitions()[eventName];
        if (prop == null)
            return;
        if (component instanceof DesignerDataSource)
            component = component.designerDataSource
        let signature = prop.eventSignature;
        if (signature == null)
            signature = eventName[0].toUpperCase() + eventName.substring(1) + "(event)";
        component[eventName] = component.id + StringUtil.stringBefore(signature, "(");
        this.redisplayProp(eventName, component[eventName]);
        this.showSave(true, false).then(async obj => {
            const fileName = await this.getCodeFileName();
            CodeEditor.addCodeFunction(fileName, component.id + signature, {
                contentsIfEmpty: this.getCodeSkeleton(),
                comment: "  /** This is an event handler for the " + eventName + " event of " + component.id + ".  */\n"
            });
        });
    }

    displayProperties() {
        this.tableProps.displayProperties(this.selectedComponents);
    }

    showCode() {
        this.showSave(true).then(async () => {
            const fileName = await this.getCodeFileName();
            CodeEditor.openCodeEditor(fileName, { contentsIfEmpty: this.getCodeSkeleton() })
        });
    }

    getCodeSkeleton() {
        const className = StringUtil.stringAfterLast(this.getActiveTab().path, "/");
        return `import { AutogenLayout${className} } from "./autogen/AutogenLayout${className}";

export class ${className} extends AutogenLayout${className} {
}
`;
    }

    async showJson() {
        const path = (await this.getResourceInfo()).get("resource_path");
        CodeEditor.openCodeEditor(path);
    }

    override getKeyHandlers(): KeyHandler[] {
        const result = [...getDesignerKeyListeners(this)];
        result.push({ key: Keys.DELETE, listener: () => this.deleteComponents(this.selectedComponents) });
        result.push({
            key: Keys.P, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null && this.selectedComponents.length === 1 && !(this.firstSelected.parent instanceof DesignerTab))
                    this.selectComponent(this.firstSelected.parent);
            }
        });
        result.push({
            key: Keys.I, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null)
                    this.tableProps.applyKeyToProp(null, "id");
            }
        });
        result.push({
            key: Keys.D, modifiers: { ctrlKey: true }, listener: () => {
                if (this.selectedComponents != null)
                    this.tableProps.applyKeyToProp(null, "dataSource");
            }
        });
        result.push({
            key: Keys.S, modifiers: { altKey: true }, listener: () => {
                this.showSave();
            }
        });
        result.push({
            key: Keys.N, modifiers: { altKey: true }, listener: () => {
                this.addNewTab();
            }
        });
        result.push({
            key: Keys.O, modifiers: { altKey: true }, listener: () => {
                this.showOpen();
            }
        });
        result.push({
            key: Keys.W, modifiers: { altKey: true }, listener: () => {
                this.selectWidthProp();
            }
        });
        result.push({
            key: Keys.P, modifiers: { shiftKey: true, ctrlKey: true }, listener: () => {
                this.addToolToSelectedContainer(new DesignerTool(this, "Panel"));
            }
        });
        result.push({
            key: Keys.P, modifiers: { altKey: true, ctrlKey: true }, listener: () => {
                this.insertIntoNewPanel(this.selectedLayoutComponents);
            }
        });
        result.push({ key: "ALL", listener: event => this.handleOtherKeys(event) });
        return result;
    }

    tabChanged(tab: DesignerTab) {
        this.updateToolbarButtonVisibility(tab);
        if (this.finishedLoading === true)
            this.storeLastSelectedTab();
        if (tab == null)
            this.selectComponent(null);
        else
            this.selectComponent(tab.designerPanel);
        this.toolsPanel.displayDataSourceTools();
        this.displayProperties();
    }

    storeLastSelectedTab() {
        const tab = this.getActiveTab();
        if (tab == null)
            localStorage.removeItem(this.localStorageKeys.selectedTab);
        else
            localStorage.setItem(this.localStorageKeys.selectedTab, JSON.stringify(tab.getDescriptor()));
    }

    selectWidthProp() {
        this.lastChangedProp = "width";
    }

    handleOtherKeys(event: KeyEvent): boolean {
        if (document.activeElement === document.body && !event.ctrlKey && !event.altKey) {
            const key = event.key;
            if (typeof key === "string" && key.length === 1 && key != "+" && key != "-") {
                this.tableProps.applyKeyToProp(key, this.lastChangedProp);
                return;
            }
        }
        event.shouldAutomaticallyStopPropagation = false;
    }

    getToolPropsForField(field: MetadataField) {
        const result: any = {};
        if (field != null) {
            result.field = field.name;
            result.name = field.name;
            if (field.dataType === "bool")
                result.toolType = "Checkbox";
            else if (field.dataType === "list")
                result.toolType = "Table";
            else if (field.extDataType === ExtendedDataType.CITY)
                result.toolType = "CityState";

            result.dataSource = this.getActiveTab().lastSelectedDataSource;
            result.caption = field.caption;
            result.toolDropped = false;
        }
        return result;
    }

    getActiveTab(): DesignerTab {
        return this.tabset.getActiveTab() as DesignerTab;
    }

    getActiveLayout(): Layout {
        return this.getActiveTab().designerPanel;
    }

    showSave(createBaseTS: boolean = false, baseTsOnly: boolean = false) {
        const tab = this.getActiveTab();
        if (tab.path == null) {
            return Api.search("layout/list").then(response => {
                const tree = new Tree({ height: 300, width: 400, borderWidth: 1, borderRadius: 4, borderColor: "strokeSecondary", nodeLeafImageName: "folder" });
                const node = tree.makeTreeNodesFromObject(response.data[0], "name", "children");
                this.removeLeafNodes(node);
                node.expanded = true;
                tree.getRootNode().setChildren(node.getChildren());
                const label = new Label({ caption: "Select save folder", fontBold: true });
                const savePath = new Textbox({ caption: "Layout name", required: true, fillRow: true });
                const panel = new Panel({ components: [label, tree, savePath] });
                return ReflectiveDialogs.showDialog(panel, { title: "Save Layout" }).then(() => {
                    const tab = this.getActiveTab();
                    const sel = tree.selectedNode;
                    if (sel == null) {
                        tree.showTooltip("You must select a folder to save this layout.", {
                            timeout: 3000, shaking: true, position: Alignment.RIGHT
                        });
                        return false;
                    }
                    let path = "";
                    for (const n of sel.path)
                        path += n.caption + "/";
                    path += savePath.text;
                    tab.path = path;
                    tab.caption = this.getTabTitle(tab.path);
                    this.storeLastSelectedTab();
                    this.saveActiveTab(createBaseTS, baseTsOnly);
                });
            });
        }
        else {
            if (isRunningInIDE() !== true && tab.baseVersion === true)
                return this.saveCustomLayout(tab);
            else
                return this.saveActiveTab(createBaseTS, baseTsOnly);
        }
    }

    async saveCustomLayout(tab: DesignerTab, savingACopy?: boolean) {
        if (!await tab.validateSave())
            return;
        const content: Panel = new Panel({ margin: 0, padding: 0, minWidth: 400, rowBreakDefault: true });
        const message = new Label();
        const descr = new Textbox({ caption: "*Name", required: true, fillRow: true, marginTop: 7 });
        content.add(message, descr);
        const panelAffectedLayouts = tab.createLayoutReferencesPanel(false);
        if (panelAffectedLayouts != null)
            content.add(panelAffectedLayouts);
        const dialogProps: Partial<YesNoDialogProps> = { noButtonCaption: "Cancel", yesButtonCaption: "Save" };
        let save = true;
        if (tab.baseVersion === true || savingACopy === true) {
            dialogProps.title = "Create Custom Layout?";
            message.caption = "By saving, you will be creating a custom version of this layout.\n\nIf you wish to continue, enter a name for this custom layout, and click Save.";
            save = await CommonDialogs.showYesNo(content, null, dialogProps);
        }
        if (save !== true)
            return;
        if (savingACopy !== true)
            return this.saveActiveTab(false, false, descr.text);
        else
            this.saveAndOpenCopy(descr.text);
    }

    removeLeafNodes(node: TreeNode) {
        for (let i = node.getChildCount() - 1; i >= 0; i--) {
            const child = node.getChild(i);
            if (child.getChildCount() === 0)
                node.removeChild(i);
            else
                this.removeLeafNodes(child);
        }
    }

    private showTest() {
        const activeTab = this.getActiveTab();
        let path = activeTab?.path;
        if (StringUtil.isEmptyString(path) === true) {
            Snackbar.showWarningSnackbar("Unable to run layout; layout has no defined path.");
            return;
        }
        if (path.startsWith("mcleod-api-")) //hack until we always have the service project name in the layout path
            path = StringUtil.stringAfter(path, "/"); // the path element should be the api project name, so skip it
        let layoutId = null;
        if (StringUtil.isEmptyString(activeTab.customLayoutId) !== true)
            layoutId = activeTab.customLayoutId;
        else if (activeTab.baseVersion === true)
            layoutId = "base";
        if (StringUtil.isEmptyString(layoutId) === false)
            path += "?" + new URLSearchParams({ layoutId: layoutId });
        Navigation.navigateTo(path, { newTab: true });
    }

    showOpen() {
        this.updateToolbarButtonVisibility(this.getActiveTab()); //this call will remove the buttons if there isn't an active tab
        const pnl = new PanelOpenLayout();
        ReflectiveDialogs.showDialog(pnl, { title: "Open Layout", height: 600, width: 500 }).then((dialog: Dialog) => {
            if (dialog.wasCancelled === true || pnl.tree?.selectedNode?.path == null)
                return;
            let path = "";
            const selectedNode = pnl.tree.selectedNode;
            selectedNode.path.forEach((node) => { path += node.caption + "/"; });
            const baseVersion: boolean = selectedNode.data.base_version;
            path = path.substring(0, path.length - 1);
            if (selectedNode.hasChildren() === true) //user tried to select a directory entry in the tree
                return; //returning here is better than an error, but is still weak sauce.  ideally the OK button in the dialog would not be available in this situation.
            this.openTab(path, baseVersion, true);
        });
    }

    async saveActiveTab(createBaseTS: boolean = false, baseTSOnly: boolean = false, descr?: string) {
        const tab = this.getActiveTab();
        if (!await tab.validateSave())
            return;
        const body = { path: tab.path, require_base_version: tab.baseVersion, id: tab.customLayoutId, create_base_ts: createBaseTS, base_ts_only: baseTSOnly, definition: serializeComponents(tab.designerPanel, tab.dataSources) };
        if (StringUtil.isEmptyString(descr) !== true)
            body["descr"] = descr;
        return Api.post(this.layoutEndpointPath, body).then((response) => {
            this.doAfterActiveTabSave(tab, baseTSOnly, response);
        }).catch(error => {
            this.doOnActiveTabSaveError(tab, error);
        });
    }

    doAfterActiveTabSave(tab: DesignerTab, baseTSOnly: boolean, response: any) {
        if (baseTSOnly !== true) {
            const oldLastOpenEntry: DesignerTabDescriptor = tab.getDescriptor();
            tab.modified = false;
            const saveResult = response.data[0];
            tab.updateFromServerResponse(saveResult);
            this.updateTabInLastOpen(oldLastOpenEntry, tab.getDescriptor());
            this.storeLastSelectedTab();
            Snackbar.showSnackbar(saveResult.message);
            this.updateToolbarButtonVisibility(tab);
        }
    }

    doOnActiveTabSaveError(tab: DesignerTab, error: any) {
        ReflectiveDialogs.showError(error);
    }

    async saveAndOpenCopy(descr?: string) {
        const tab = this.getActiveTab();
        const body = { path: tab.path, require_base_version: tab.baseVersion, create_base_ts: false, base_ts_only: false, definition: serializeComponents(tab.designerPanel, tab.dataSources) };
        if (StringUtil.isEmptyString(descr) !== true)
            body["descr"] = descr;
        return Api.post(this.layoutEndpointPath, body).then((response) => {
            this.doAfterSaveACopy(response, tab.path);
        }).catch(reason => ReflectiveDialogs.showError(reason));
    }

    private doAfterSaveACopy(response: any, path: string) {
        const saveResult = response.data[0];
        const tab = this.openTab(path, false, true, saveResult.id);
        this.selectComponent(tab.designerPanel);
        this.displayProperties();
        tab.modified = false;
        tab.updateFromServerResponse(saveResult);
        this.addTabToLastOpen(tab.getDescriptor());
        this.storeLastSelectedTab();
        Snackbar.showSnackbar("You are now editing the copy of the custom layout.  It has already been saved to the database.");
        this.updateToolbarButtonVisibility(tab);
    }

    getLastSelected(): DesignerTabDescriptor {
        try {
            const object = JSON.parse(localStorage.getItem(this.localStorageKeys.selectedTab));
            return DesignerTabDescriptor.createFromObject(object);
        } catch {
            localStorage.removeItem(this.localStorageKeys.selectedTab);
            return null;
        }
    }

    private getLastOpen(): DesignerTabDescriptor[] {
        const open = localStorage.getItem(this.localStorageKeys.openTabs);
        if (open == null || open.length === 0)
            return [];
        else
            try {
                const result = [];
                const array: [] = JSON.parse(open);
                if (array != null) {
                    for (const object of array) {
                        result.push(DesignerTabDescriptor.createFromObject(object));
                    }
                }
                return result;
            } catch {
                localStorage.removeItem(this.localStorageKeys.openTabs);
                return [];
            }
    }

    private addTabToLastOpen(entry: DesignerTabDescriptor) {
        const index = this.getLastOpenIndex(entry);
        if (index < 0) {
            const lastOpen = this.getLastOpen();
            lastOpen.push(entry);
            this.updateLastOpen(lastOpen);
        }
    }

    updateTabInLastOpen(oldEntry: DesignerTabDescriptor, newEntry: DesignerTabDescriptor) {
        const lastOpen = this.getLastOpen();
        const index = this.getLastOpenIndex(oldEntry, lastOpen);
        if (index >= 0)
            lastOpen.splice(index, 1);
        lastOpen.push(newEntry);
        this.updateLastOpen(lastOpen);
    }

    deleteTabFromLastOpen(entry: DesignerTabDescriptor) {
        const lastOpen = this.getLastOpen();
        const index = this.getLastOpenIndex(entry, lastOpen);
        if (index >= 0) {
            lastOpen.splice(index, 1);
            this.updateLastOpen(lastOpen);
        }
    }

    private updateLastOpen(lastOpen: DesignerTabDescriptor[]) {
        localStorage.setItem(this.localStorageKeys.openTabs, JSON.stringify(lastOpen));
    }

    private getLastOpenIndex(descriptor: DesignerTabDescriptor, lastOpen: DesignerTabDescriptor[] = this.getLastOpen()) {
        for (let i = 0; i < lastOpen.length; i++)
            if (descriptor.equals(lastOpen[i]))
                return i;
        return -1;

    }

    modified() {
        this.getActiveTab().modified = true;
    }

    isModified() {
        for (const tab of this.tabset.components)
            if ((tab as DesignerTab).modified)
                return true;
        return false;
    }

    applyChangeToSelectedComponents(data, newValue) {
        this.modified();
        designerApplyChangeToSelectedComponents(this.selectedComponents, this.getActiveTab(), data, newValue, this.tableProps);
    }

    addNewTab() {
        const tab = this.openTab(null, true, true);
        this.selectComponent(tab.designerPanel);
        this.displayProperties();
    }

    openTabFromDescriptor(tabDescriptor: DesignerTabDescriptor, selectTab: boolean) {
        this.openTab(tabDescriptor.name, tabDescriptor.baseVersion, selectTab, tabDescriptor.customLayoutId);
    }

    openTab(path: string, requireBaseVersion: boolean, selectTab: boolean, customLayoutId?: string/*, definition?: string*/): DesignerTab | undefined {
        if (path != null) {
            for (const tab of this.tabset) {
                if (tab.path === path && tab.baseVersion === requireBaseVersion && tab.customLayoutId === customLayoutId) {
                    tab.select();
                    return;
                }
            }
        }
        const tab = new DesignerTab(this, path, { tabStyle: this.tabset.tabStyle, baseVersion: requireBaseVersion });
        tab.customLayoutId = customLayoutId;
        let title = "New layout";
        if (path != null) {
            title = this.getTabTitle(path);
            this.searchAndLoadLayout(tab, requireBaseVersion);
        }
        tab.caption = title;
        tab._designer = this;
        this.tabset.add(tab);
        if (selectTab === true)
            this.tabset.selectedIndex = this.tabset.getComponentCount() - 1;
        return tab;
    }

    searchAndLoadLayout(tab: DesignerTab, requireBaseVersion: boolean) {
        Api.search(this.layoutEndpointPath, this.getLayoutSearchFilter(tab.path, requireBaseVersion, tab.customLayoutId)).then((response) => {
            const saveResult = response.data[0];
            this.loadLayoutFromSaveResult(saveResult, tab);
        }).catch(error => {
            let msg = error?.toString();
            const index = msg.indexOf("Could not find a layout ");
            if (index >= 0)
                msg = msg.substring(index, msg.indexOf("\n", index));
            tab.addErrorLabel(msg);
        });
    }

    private getLayoutSearchFilter(path: string, requireBaseVersion: boolean, customLayoutId?: string): any {
        const result = { path: path, require_base_version: requireBaseVersion, apply_field_level_perms: false, apply_field_level_licensing: false };
        if (customLayoutId != null)
            result["id"] = customLayoutId;
        return result;
    }

    private loadLayoutFromSaveResult(saveResult: any, tab: DesignerTab) {
        const def = JSON.parse(saveResult.definition);
        tab.updateFromServerResponse(saveResult);
        this.loadTabLayout(tab, def);
    }

    private loadTabLayout(tab: DesignerTab, def: any) {
        this.addTabToLastOpen(tab.getDescriptor());
        this.storeLastSelectedTab();
        if (def.dataSources != null)
            this.createDataSourcesFromDef(tab, def.dataSources, tab.path);

        let owner = DynamicLoader.getClassForPath(tab.path);
        if (owner != null)
            owner = new owner();
        let components = deserializeComponents(owner, def, this, { applyFieldLevelPermissions: false, applyFieldLevelLicensing: false }, tab.dataSources, null);
        for (const prop in tab.designerPanel.getPropertyDefinitions())
            if (def[prop] !== undefined && prop !== "mainDataSource") // Layout._deserializeSpecialProps handles mainDataSource
                tab.designerPanel[prop] = def[prop];
        tab.designerPanel.mainDataSource = components[0].mainDataSource; // this is embarassing
        components = components[0].components;
        for (let i = 0; i < components.length; i++)
            tab.designerPanel.add(components[i]);
        this.selectComponent(tab.designerPanel);
        this.displayProperties();
        this.tabLayoutLoaded(tab);
    }

    private tabLayoutLoaded(tab: DesignerTab) {
        if (this.getActiveTab() == tab)
            this.updateToolbarButtonVisibility(tab);
    }

    getOwnerForActiveTab() {
        const tab = this.getActiveTab();
        if (tab.owner == null) {
            const ownerFunc = DynamicLoader.getClassForPath(tab.path);
            if (ownerFunc != null)
                tab.owner = new ownerFunc();
        }
        return tab.owner;
    }

    createDataSourcesFromDef(tab: DesignerTab, sources: DataSourceProps[], layoutName: string): void {
        for (let i = 0; sources != null && i < sources.length; i++) {
            const dataSource = new DataSource({ ...sources[i] }, null, this, tab);
            dataSource.layoutName = layoutName;
            tab.dataSources[dataSource.id] = dataSource;
            tab.dataSourcePanel.addDesignerDataSource(new DesignerDataSource(this, dataSource));
        }
    }

    private getTabTitle(path: string): string {
        const slashPos = path.lastIndexOf("/");
        if (slashPos >= 0)
            return path.substring(slashPos + 1);
        else
            return path;
    }

    async closeTab(tab: DesignerTab) {
        if (!tab._modified || await ReflectiveDialogs.showYesNo("Are you sure you want to close this tab without saving your changes?", "Close Without Saving?")) {
            tab.parent.remove(tab);
            if (this.tabset.getComponentCount() === 0)
                this.addNewTab();
        }
    }

    private tabClosed(event: TabCloseEvent) {
        const tab = event.tab as DesignerTab;
        if (tab.path != null)
            this.deleteTabFromLastOpen(tab.getDescriptor());
    }

    private getNextNumber(toolName: string, owner: DesignerTab = this.getActiveTab()) {
        for (let i = 1; i < 1000; i++)
            if (document.getElementById(toolName + i) == null && owner[toolName + i] == null)
                return i;
        throw new Error("Could not get the next available component number.");
    }

    redisplayProp(propName: string, value: string) {
        this.tableProps.redisplayProp(propName, value);
        if (propName === "id")
            this.getActiveTab().dataSourcePanel.updateIds();
    }

    selectComponent(component: DesignableObject, add: boolean = false) {
        if (component != null && !this.canSelectComponent(component))
            return;

        if (component instanceof Component) {
            let parent = component.parent;
            while (parent != null) {
                if (parent instanceof Layout && parent.isNested) {
                    component = parent;
                    break;
                }
                parent = parent.parent;
            }
        }
        designerHandleComponentSelection(this.selectedComponents, component as Component, add, this.tableProps);
        this.selectedLayoutComponents = this.selectedComponents.filter(comp => this.isActiveLayoutComponent(comp)) as Component[];
        if (this.selectedComponents.length === 1 && this.selectedComponents[0] instanceof DesignerDataSource && this.tabset.getActiveTab() != null) {
            this.getActiveTab().lastSelectedDataSource = this.selectedComponents[0].designerDataSource;
            this.toolsPanel.displayDataSourceTools();
            this.getActiveTab().dataSourcePanel.enableButtonModelView(true);
        } else {
            if (this.tabset.getActiveTab() != null && this.selectedComponents.length === 1)
                this.getActiveTab().dataSourcePanel.enableButtonModelView(false);
            this.dragDropHandler.componentSelected();
        }
    }

    canSelectComponent(component: any) {
        return component == this.getActiveLayout() ||
            component instanceof TableCell ||
            component instanceof DesignerDataSource ||
            component?.deserialized === true
    }

    /**
     * Called by UIDesignerActionHistory when an action happens
     */
    _notifyAction(result: any) {
        const undoAction = getDesignerUndoAction(this);
        const redoAction = getDesignerRedoAction(this);
        this.buttonUndo.enabled = undoAction != null;
        this.buttonUndo.tooltip = undoAction == null ? "Nothing to undo" : "Undo: " + undoAction.toString();
        this.buttonRedo.enabled = redoAction != null;
        this.buttonRedo.tooltip = redoAction == null ? "Nothing to redo" : "Redo: " + redoAction.toString();
        if (result != null)
            this.notifyComponentsAdded(result.componentsAdded, result.container)
    }

    notifyComponentsAdded(components: Component[], container: Container) {
        components?.forEach(comp => {
            this.notifyComponentAdded(comp, container);
            if (comp instanceof Container)
                this.notifyComponentsAdded(comp.components, container);
        });
    }

    notifyComponentAdded(component: any, container: Container) {
        // the deserialized is a prop we use to distinguish a component defined in the layout json
        // vs components added programatically; those components may already know they were not deserialized
        if (component.deserialized !== false)
            component.deserialized = true;
    }

    addToolToSelectedContainer(tool: DesignerTool): Component {
        const selectedComponent = this.firstSelected;
        if (this.isActiveLayoutComponent(selectedComponent)) {
            let container = null;
            if ((selectedComponent as any).acceptsTool != null && (selectedComponent as any).acceptsTool(tool))
                container = selectedComponent as Container
            else
                container = selectedComponent.parent;
            return this.addToolToContainer(tool, container, false);
        } else {
            Snackbar.showSnackbar("A parent component must be selected to add this tool to.");
            return null;
        }
    }

    toolDropped(tool: DesignerTool, container: Container, index: number): Component {
        const toolProps = tool?.toolProps;
        const readOnly = this.toolsPanel.switchDragMode.checked && toolProps != null && Object.keys(toolProps).length > 1;
        return this.addToolToContainer(tool, container, readOnly, index);
    }

    // Currently used by Stepper and Tabset
    addTool(toolName: string, container: Container): Component {
        const tool = new DesignerTool(this, toolName);
        return this.addToolToContainer(tool, container, false, null, false);
    }

    addToolToContainer(tool: DesignerTool, container: Container, readOnly: boolean, index?: number, validateContainer: boolean = true): Component {
        if (validateContainer && !this.canAddComponentToContainer(tool, container))
            return null;
        this.modified();
        const componentType = readOnly ? "label" : tool.toolName.toLowerCase();
        const toolProps = tool.toolProps;
        const nextNumber = this.getNextNumber(componentType);
        const component = ComponentTypes.createComponentOfType(componentType, { id: componentType + nextNumber });
        component._designer = this;
        component._initialDropInDesigner();
        this.getActiveTab()[componentType + nextNumber] = component;
        if ("caption" in component)
            component.caption = componentType + nextNumber;
        if (toolProps != null) {
            if ("caption" in toolProps) {
                if (container instanceof TableCell && container.col != null && container.col.heading.caption.startsWith("Column ")) {
                    container.col.heading.caption = toolProps.caption;
                    container.col.headingCell.caption = toolProps.caption;
                }
            }
            if (readOnly)
                delete toolProps.caption;
            if (container instanceof TableCell && container.col != null) {
                const serialized = serializeComponents(container.components, null);
                const componentDefs = JSON.parse(serialized);
                container.col.cellDef = { cellProps: { table: container._table } };
                container.col.cellDef.def = { type: "cell", components: componentDefs };
            }
        }
        this.addComponentToContainer(component, container, index);
        component.setProps(toolProps);
        this.selectComponent(component, false);
        return component;

    }

    addComponentToContainer(component: any, container: Container, index?: number) {
        if (component != null && container != null) {
            doDesignerAction(this, new ActionAddComponent(component, container, index));
            this.notifyComponentAdded(component, container);
        }
    }

    addDesignerContainerProperties(container: Component, minWidth: number, minHeight: number, allowDropFunc: (tool: DesignerTool) => boolean) {
        setClassIncluded(container, designerClasses.designerContainer);
        if (container.width === undefined && container.minWidth === undefined)
            container.element.style.minWidth = minWidth + "px";
        if (container.minHeight === undefined)
            container.element.style.minHeight = minHeight + "px";
        (container as DesignerContainer).acceptsTool = (tool: DesignerTool) => {
            return tool != null && (allowDropFunc == null || allowDropFunc(tool));
        };
        this.addDragAndDropListeners(container);
    }

    addDragAndDropListeners(comp: Component) {
        this.dragDropHandler.addListeners(comp);
    }

    displayDataSourceTools() {
        this.toolsPanel.displayDataSourceTools();
    }

    componentDropped(comp: Component) {
    }

    get allowsDrop(): boolean {
        return true;
    }

    isDesignerContainer(comp: Component): boolean {
        return comp instanceof Container && comp._element.classList.contains(designerClasses.designerContainer);
    }

    canAddComponentToContainer(comp: Component, container: any) {
        return comp != null &&
            this.isActiveLayoutComponent(container) &&
            (this.isDesignerContainer(container)) &&
            container.isNested != true &&
            container.acceptsTool(comp);
    }

    isActiveLayoutComponent(comp: DesignableObject): boolean {
        return this.isLayoutComponent(comp, this.getActiveLayout())
    }

    isLayoutComponent(comp: DesignableObject, layout: Layout): boolean {
        const layoutElement = layout?._element;
        const compElement = comp instanceof Component ? comp._element : null;
        return layoutElement != null && compElement != null && DOMUtil.isOrContains(layoutElement, compElement);
    }

    insertIntoNewPanel(components: Component[]) {
        if (components?.length == 1 && components[0] == this.getActiveLayout()) {
            doDesignerAction(this, new ActionInsertIntoNewPanel(this.getActiveLayout().components));
        } else if (components?.length > 0) {
            const conatiner = components[0].parent;
            if (components.every(comp => comp.parent == conatiner)) {
                doDesignerAction(this, new ActionInsertIntoNewPanel(this.selectedLayoutComponents));
            } else {
                Snackbar.showSnackbar("Only components within the same container can be selected for this action.");
            }
        } else {
            Snackbar.showSnackbar("A component must be selected to create new Panel.");
        }
    }

    updateToolbarButtonVisibility(tab: DesignerTab) {
        const activeTabPresent = this.getActiveTab() != null;
        this.buttonRun.visible = activeTabPresent;
        this.buttonSave.visible = activeTabPresent;
        this.buttonSaveNewVersion.visible = tab != null ? tab.isCustom : false
        this.buttonManageVersions.visible = activeTabPresent;
        this.buttonUndo.visible = activeTabPresent;
        this.buttonRedo.visible = activeTabPresent;
    }

    async showManageVersions() {
        const tab = this.getActiveTab();
        const layout = Layout.getLayout("designer/ui/UILayoutVersions") as UILayoutVersions;
        layout.enclosingDesigner = this;
        layout.layoutPath = tab.path;
        layout.layoutCaption = tab.caption;
        layout.addLayoutLoadListener(() => {
            const sod = new SlideoutDecorator({
                layout: layout,
                width: Math.max(window.innerWidth * 0.75, 800),
                fillVerticalSpace: true,
                title: "Manage Layout Versions - " + tab.caption,
                doAfterSlideIn: (decorator: SlideoutDecorator) => {
                    layout.mainDataSource.search({ name: tab.path }).then(response => {
                        layout.positionBaseComponents();
                    });
                }
            });
        });
    }

    deleteComponents(components: DesignableObject[]) {
        if (components == null)
            return;
        for (const component of components)
            doDesignerAction(this, new ActionDeleteComponent(component));
        this.selectComponent(null, false);
    }

    addTableColumn(table: Table): TableColumn {
        const action = new ActionAddTableColumn(table);
        doDesignerAction(this, action);
        return action.tableColumn;
    }

    executeChangePropAction(comp: Component, propName: string, newValue: any, oldValue: any, redisplayProp: boolean = false) {
        doDesignerAction(this, new ActionChangeComponentProperty(comp, propName, newValue, oldValue, redisplayProp));
    }

    doBeforePropChanged(component: Component, propName: string, propsSeen: string[] = []) {
        // Use the lines below if this method ever defines any logic.
        // Also, include these lines in any overriding method.

        // log.debug("Invoked doBeforePropChanged for property: %o", propName);
        // if (propsSeen.includes(propName)) {
        //     log.debug("Property %o already seen in doBeforePropChanged", propName);
        //     return;
        // }
        // propsSeen.push(propName);

        // LOGIC FOR THIS METHOD WOULD GO HERE

        // const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;
        // log.debug("Property %o affects other properties: %o", propName, affectsProps);
        // affectsProps?.forEach(affect => this.doBeforePropChanged(component, affect, propsSeen));
    }

    doAfterPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean, propsSeen: string[] = []) {
        log.debug("Invoked doAfterPropChanged for property: %o", propName);
        redisplayProp = redisplayProp && component === this.firstSelected;
        const affectsProps: string[] = component.getPropertyDefinitions()[propName]?.affectsProps;

        if (propsSeen.includes(propName)) {
            log.debug("Property %o already seen in doAfterPropChanged", propName);
            return;
        }
        propsSeen.push(propName);

        // sync the tableProps.row data and captions if component is the first selected
        if (component === this.firstSelected) {
            const data = this.tableProps.getRowData(propName);
            if (data)
                data.value = newValue;

            if (redisplayProp === true)
                this.redisplayProp(propName, newValue);

            this.tableProps.syncRowCaptionStyle(propName);
        }

        this.syncPropChanged(component, propName, oldValue, newValue, redisplayProp);
        log.debug("Property %o affects other properties: %o", propName, affectsProps);
        affectsProps?.forEach(affect => this.doAfterPropChanged(component, affect, null, component[affect], true, propsSeen));
    }

    syncPropChanged(component: Component, propName: string, oldValue: any, newValue: any, redisplayProp?: boolean) { }

    canModifyProp(propName: string, component: Component): boolean { return true; }

    hasDesignerToolAccessForComponent(comp: Component) {
        if (comp != null)
            return this.getDesignerToolForComponent(comp) != null;
        return false;
    }

    getDesignerToolForComponent(comp: Component): DesignerTool {
        return this.toolsPanel?.panelMainTools?.components?.
            find(tool => tool instanceof DesignerTool && comp.typeName == tool?.toolName?.toLowerCase()) as DesignerTool;
    }
}
