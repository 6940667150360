import { ArrayUtil, DOMUtil, DynamicLoader, makeStyles, StringUtil, VerticalAlignment } from "@mcleod/core";
import { HorizontalAlignment } from "@mcleod/core/src/constants/Alignment";
import { deserializeComponents, Overlay } from "../..";
import { Component } from "../../base/Component";
import { ComponentPropDefinitions } from "../../base/ComponentProps";
import { ComponentTypes } from "../../base/ComponentTypes";
import { Container } from "../../base/Container";
import { TransitionOptions } from "../../base/TransitionOptions";
import { KeyHandler } from "../../events/KeyHandler";
import { KeyHandlerGroup } from "../../events/KeyHandlerGroup";
import { OverlayProps } from "../../page/OverlayProps";
import { AddedSnackBarInfo, Snackbar } from "../../page/Snackbar";
import { Toast } from "../../page/Toast";
import { ComponentCreationCallback } from "../../serializer/ComponentDeserializer";
import { PanelPropDefinitions, PanelProps } from "./PanelProps";
import { ScreenStack } from "./ScreenStack";

const classes = makeStyles("pnl", {
    panelRow: { display: "flex", flexDirection: "row", scrollBehavior: "smooth", alignItems: "center" },
    panel: { display: "flex", flexDirection: "column" },
    noSelect: { WebkitTouchCallout: "none", WebkitUserSelect: "none", KhtmlUserSelect: "none", MozUserSelect: "none", MsUserSelect: "none", userSelect: "none" },
    rowFillHeight: { flex: "1", overflow: "hidden", alignItems: "unset" }
});
let backgroundImageClassIndex: number = 1;

export class Panel extends Container implements PanelProps {
    public rowBreakDefault: boolean;
    private _scrollX: boolean;
    private _scrollY: boolean;
    protected _shouldAddDesignerContainerProperties: boolean = true;
    private _wrap: boolean;
    private _allowSelect: boolean = true;
    private _rows: HTMLElement[];
    private _backgroundImageClass: string;
    private _backgroundImageBytes: string;
    private _verticalAlign: VerticalAlignment;
    private _keyHandlerGroup: KeyHandlerGroup;
    private _snackStack: Panel[];
    private _toastStack: Panel[];
    private _dismissPopupsUnmountListener: () => void;

    constructor(props?: Partial<PanelProps>, callSetProps: boolean = true) {
        super("div", props);
        this.wrap = true;
        this._addDefaultClassName();
        if (callSetProps)
            this.setProps(props);
    }

    protected _addDefaultClassName() {
        this.setClassIncluded(classes.panel);
    }

    get components(): Component[] {
        return this._components;
    }

    set components(value: Component[]) {
        // removing all existing comps and adding the new ones can definitely be improved.  Brute force for now.
        if (this._components != null)
            for (let i = this._components.length - 1; i >= 0; i--)
                this.removeAt(i);
        if (value != null)
            for (const comp of value)
                this.add(comp);
    }

    swap(comp1: Component, comp2: Component) {
        const index1 = this._components.indexOf(comp1);
        const index2 = this._components.indexOf(comp2);
        ArrayUtil.switchArrayElements(this._components, index1, index2);
        const parent2 = comp2._element.parentElement;
        const comp2Sibling = comp2._element.nextSibling;
        comp1._element.parentElement.replaceChild(comp2._element, comp1._element);
        parent2.insertBefore(comp1._element, comp2Sibling);
    }

    setProps(props: Partial<PanelProps>) {
        super.setProps(props);
    }

    removeAt(index: number) {
        if (index >= 0 && index < this.components.length) {
            const component = this.components[index];
            if (component instanceof Panel)
                component.dismissAllPopups();
            this.components.splice(index, 1);
            this.layoutRows();
        }
    }

    add(...components: Component[]): Component {
        for (const component of components) {
            if (component == null)
                throw new Error("Attempt to add a null component to a container.");
            const lastComponentIsRowBreak = this.components.length > 0 && this.shouldRowBreak(this.components[this.components.length - 1]);
            this.components.push(component);
            if (this._rows == null) {
                if (component.isRow)
                    this._rows = [component._element];
                else
                    this._rows = [this.createRow()];
            }
            else if (lastComponentIsRowBreak) {
                if (component.isRow)
                    this._rows.push(component._element);
                else
                    this._rows.push(this.createRow());
            }
            const thisRow = this._rows[this._rows.length - 1];
            if (component.fillHeight === true)
                thisRow.className = classes.panelRow + " " + classes.rowFillHeight;
            if (this.wrap === false)
                thisRow.style.flexWrap = "nowrap";
            else if (this.wrap === true) {
                thisRow.style.flexWrap = "wrap";
            }
            if (component.isRow)
                this._element.appendChild(component._element);
            else
                thisRow.appendChild(component._element);
            component.parent = this;

            if (this.scrollY) {
                if (this._rows.length === 1)
                    this._rows[0].style.overflowY = "auto";
                else
                    this._rows[0].style.overflowY = "";
            }
            if (this.scrollX) {
                if (this._rows.length === 1)
                    this._rows[0].style.overflowX = "auto";
                else
                    this._rows[0].style.overflowX = "";
            }
        }
        if (components.length > 0)
            return components[0];
        return undefined;
    }

    addFromArray(components: Component[]): Component {
        let result: Component = null;
        if (components != null) {
            for (const component of components) {
                result = this.add(component);
            }
        }
        return result;
    }

    addIfNotPresent(...components: Component[]): Component {
        let result: Component = null;
        for (const component of components) {
            if (!this.contains(component))
                result = this.add(component);
        }
        if (result != null)
            return result;
        if (components.length > 0)
            return components[0];
        return undefined;
    }

    public get rows() {
        return this._rows;
    }

    public get scrollY(): boolean {
        return this._scrollY;
    }

    public set scrollY(value: boolean) {
        this._scrollY = value;
        if (value)
            this._element.style.overflowY = "auto";
        else
            this._element.style.overflowY = "";
    }

    public get scrollX(): boolean {
        return this._scrollX;
    }

    public set scrollX(value: boolean) {
        this._scrollX = value;
        if (value)
            this._element.style.overflowX = "auto";
        else
            this._element.style.overflowX = "";
    }

    shouldRowBreak(child: Component) {
        if (child.isRow)
            return true;
        if (this.rowBreakDefault === false)
            return child._rowBreak === true;
        else
            return child._rowBreak !== false;
    }

    createRow(): HTMLElement {
        const result = document.createElement("div");
        result.className = classes.panelRow;
        this.setRowProps(result);

        this._element.appendChild(result);
        return result;
    }

    setRowProps(row: HTMLElement) {
        row.style.justifyContent = this.getVerticalAlignStyle();
        if (this.align != null)
            row.style.justifyContent = this.align.toString();
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value) {
        super._designer = value;
        if (value != null && this._shouldAddDesignerContainerProperties !== false && value.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 80, 34,
                tool => {
                    if ((tool instanceof Component))
                        return this.allowDropInDesigner(tool);
                    return false;
                });
    }

    allowDropInDesigner(component: Component): boolean {
        return true;
    }

    childBreakChanged(child: Component) {
        this.layoutRows();
    }

    get align(): HorizontalAlignment {
        return this._align;
    }

    set align(value: HorizontalAlignment) {
        this._align = value;
        if (value === HorizontalAlignment.RIGHT)
            this._element.style.alignItems = "flex-end";
        else if (value === HorizontalAlignment.CENTER)
            this._element.style.alignItems = "center";
        else
            this._element.style.alignItems = "";
    }

    get verticalAlign(): VerticalAlignment {
        return this._verticalAlign == null ? PanelPropDefinitions.getDefinitions().verticalAlign.defaultValue : this._verticalAlign;
    }

    set verticalAlign(value: VerticalAlignment) {
        this._verticalAlign = value;
        if (value === VerticalAlignment.TOP)
            this._element.style.justifyContent = "flex-start";
        else if (value === VerticalAlignment.BOTTOM)
            this._element.style.justifyContent = "flex-end";
        else
            this._element.style.justifyContent = "";
    }

    private getVerticalAlignStyle(): string {
        const align = this.verticalAlign;
        if (align === VerticalAlignment.CENTER)
            return ""; // fall back to CSS style
        if (align === VerticalAlignment.TOP)
            return "flex-start";
        else if (align === VerticalAlignment.BOTTOM)
            return "flex-end";
        else
            return "unset";
    }

    contains(comp: Component): boolean {
        return this.indexOf(comp) >= 0;
    }

    override removeAll() {
        this._components = [];
        this.layoutRows();
    }

    layoutRows() {
        this._element.innerHTML = "";
        const newComponents = [...this.components];
        this._components = [];
        this._rows = null;
        for (let i = 0; i < newComponents.length; i++)
            this.add(newComponents[i]);
    }

    reLayout() {
        this.layoutRows();
    }

    get backgroundImageBytes() {
        return this._backgroundImageBytes;
    }

    set backgroundImageBytes(value) {
        if (this._backgroundImageClass == null) {
            this._backgroundImageClass = "" + backgroundImageClassIndex++;
            this._backgroundImageBytes = value;
            makeStyles("bkg", {
                [this._backgroundImageClass]: {
                    backgroundImage: "url(data:image/jpeg;base64," + value + ")",
                    backgroundSize: "cover"
                }
            });
            this.setClassIncluded("bkg-" + this._backgroundImageClass, true);
        }
        else
            this.setClassIncluded("bkg-" + this._backgroundImageClass, false);
    }


    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback): string[] {
        const compSpecial = super._deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback);
        if (compDef.components != null && compDef.components.length > 0) {
            const props = { ...defaultPropValues };
            delete props.id;
            const children = deserializeComponents(componentOwner, compDef.components, this._designer, props, dataSources, componentCreationCallback);
            for (let i = 0; i < children.length; i++)
                this.add(children[i]);
        }
        return [...compSpecial, "components"];
    }

    // focus() {
    //   for (let i = 0; i < this.components.length; i++) {
    //     const comp = this.components[i];
    //     if (comp.focus != null && comp.enabled !== false && comp.visible !== false)
    //       if (comp.focus())
    //         return true;
    //   }
    //   return false;
    // }

    override getPropertyDefinitions(): ComponentPropDefinitions {
        return PanelPropDefinitions.getDefinitions();
    }

    get allowSelect(): boolean {
        return this._allowSelect;
    }

    set allowSelect(value: boolean) {
        this._allowSelect = value;
        this.setClassIncluded(classes.noSelect, !value)
    }

    override get serializationName() {
        return "panel";
    }

    addToKeyHandlerStack() {
        this.fillKeyHandlerGroup();
        ScreenStack.push(this);
    }

    removeFromKeyHandlerStack() {
        ScreenStack.pop(this);
        this.getKeyHandlerGroup().clear();
    }

    /**
     * This method will return any key handlers that exist within this panel.
     *
     * @returns void
     */
    getKeyHandlerGroup() {
        this._initKeyHandlerGroup();
        return this._keyHandlerGroup;
    }

    /**
     * This method will collect key handlers from this panel and its child components.
     *
     * @returns void
     */
    fillKeyHandlerGroup(recreate: boolean = false) {
        if (this._keyHandlerGroup != null && recreate !== true)
            return;
        this._initKeyHandlerGroup(recreate);
        this._addKeyHandlersToGroup(this.getKeyHandlers());
        for (const component of this.getRecursiveChildren()) {
            this._addKeyHandlersToGroup(component.getKeyHandlers());
        }
        this._keyHandlerGroup.sort();
    }

    private _addKeyHandlersToGroup(compKeyHandlers: KeyHandler[]) {
        if (ArrayUtil.isEmptyArray(compKeyHandlers) === true)
            return;
        for (const compKeyHandler of compKeyHandlers) {
            this._keyHandlerGroup.addKeyHandler(compKeyHandler);
        }
    }

    private _initKeyHandlerGroup(recreate: boolean = false) {
        if (this._keyHandlerGroup == null || recreate === true)
            this._keyHandlerGroup = new KeyHandlerGroup();
    }

    public override slideIn(options?: TransitionOptions, displayOverlay?: boolean, overlayProps?: Partial<OverlayProps>): Promise<any> {
        if (displayOverlay !== true)
            this.addToKeyHandlerStack();
        return super.slideIn(options, displayOverlay, overlayProps);
    }

    public override slideOut(options?: TransitionOptions, componentToFocusOnClose?: Component): Promise<any> {
        this.dismissAllPopups();
        if (Overlay.findOverlay(this) == null)
            this.removeFromKeyHandlerStack();
        return super.slideOut(options, componentToFocusOnClose);
    }

    public dismissAllPopups() {
        this.dismissAllSnackbars();
        this.dismissAllToasts();
        this.dismissCurrentTooltip();
    }

    /**
     * Dismisses the current tooltip, but only if the panel being removed is not within that tooltip.
     * If it is, we are trying to update the tooltip, and we shouldn't dismiss it.
     * @returns void
     */
    private dismissCurrentTooltip() {
        const currentTooltip = ScreenStack.getOldestTooltip();
        if (currentTooltip == null || currentTooltip.isOrContains(this))
            return;
        DynamicLoader.getModuleByName("components/page/Tooltip").hideTooltip();
    }

    public getSnackIndex(snack: Panel): number {
        if (this._snackStack?.length > 0)
            return this._snackStack.indexOf(snack);
        return -1;
    }

    private _addDismissPopupsUnmountListener() {
        if (this._dismissPopupsUnmountListener == null) {
            this._dismissPopupsUnmountListener = () => this.dismissAllPopups();
            this.addUnmountListener(this._dismissPopupsUnmountListener);
        }
    }

    private _initSnackStack() {
        if (this._snackStack == null) {
            this._snackStack = [];
            this._addDismissPopupsUnmountListener();
        }
    }

    private _nullEmptySnackStack() {
        if (this._snackStack.length === 0)
            this._snackStack = null;
    }

    /**
     * Push a new Snackbar onto this Panel's snack stack.
     *
     * @param snack The Snackbar that is to be added.
     * @returns An AddedSnackBarInfo object, which contains information about the snack just added so that it can be rendered
     */
    pushSnackbar(snack: Panel): AddedSnackBarInfo {
        this._initSnackStack();
        let parentPanelBottom = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("bottom", this._element));
        if (parentPanelBottom < 0)
            parentPanelBottom = 0;
        let parentPanelLeft = DOMUtil.getStyleAttrAsNumber(DOMUtil.getComputedStyle("left", this._element));
        if (parentPanelLeft < 0)
            parentPanelLeft = 0;
        const zIndex = this._getNewSnackToastZIndex();
        this._snackStack.push(snack);
        const result: AddedSnackBarInfo = {
            parentPanelBottom: parentPanelBottom,
            parentPanelLeft: parentPanelLeft,
            zIndex: zIndex
        };
        if (this._snackStack.length > 1) {
            result.previousSnackHeight = this._snackStack[this._snackStack.length - 2]._element.clientHeight;
            result.previousSnackBottom = DOMUtil.getStyleAttrAsNumber(this._snackStack[this._snackStack.length - 2].bottom);
        }
        return result;
    }

    popSnackbar(snack: Panel) {
        if (this._snackStack == null)
            return;
        const removedSnackIndex = this.getSnackIndex(snack);
        ArrayUtil.removeFromArray(this._snackStack, snack);
        DynamicLoader.getModuleByName("components/page/Snackbar").Snackbar.moveNewerSnackbarsDown(this._snackStack, removedSnackIndex, snack._element.clientHeight);
        this._nullEmptySnackStack();
    }

    private dismissAllSnackbars(id?: string) {
        if (this._snackStack == null)
            return;
        for (const snack of this._snackStack) {
            const snackAsSnackbar = (snack as Snackbar);
            if (StringUtil.isEmptyString(id) === true || id === snackAsSnackbar.snackbarId)
                snackAsSnackbar.dismiss(0, false);
        }
    }

    getOldestSnackbar(id?: string): Panel {
        if (this._snackStack == null)
            return null;
        if (StringUtil.isEmptyString(id))
            return this._snackStack?.length > 0 ? this._snackStack[0] : null;
        for (const snack of this._snackStack) {
            if ((snack as Snackbar).snackbarId === id)
                return snack;
        }
        return null;
    }

    containsSnackbar(snack: Panel) {
        return this._snackStack?.includes(snack);
    }

    private _initToastStack() {
        if (this._toastStack == null) {
            this._toastStack = [];
            this._addDismissPopupsUnmountListener();
        }
    }

    private _nullEmptyToastStack() {
        if (this._toastStack.length === 0)
            this._toastStack = null;
    }

    /**
     * Push a new Toast onto this Panel's toast stack.
     *
     * @param toast The Toast that is to be added.
     */
    pushToast(toast: Panel) {
        this._initToastStack();
        toast.zIndex = this._getNewSnackToastZIndex();
        this._toastStack.push(toast);
    }

    popToast(toast: Panel) {
        if (this._toastStack == null)
            return;
        ArrayUtil.removeFromArray(this._toastStack, toast);
        this._nullEmptyToastStack();
    }

    private dismissAllToasts(id?: string) {
        if (this._toastStack == null)
            return;
        for (const toast of this._toastStack) {
            const toastAsToast = (toast as Toast);
            if (StringUtil.isEmptyString(id) === true || id === toastAsToast.toastId)
                toastAsToast.dismiss(0, true);
        }
    }

    getNewestToast(id?: string): Panel {
        if (this._toastStack == null)
            return null;
        if (StringUtil.isEmptyString(id))
            return ArrayUtil.getLastElement(this._toastStack);
        for (const toast of this._toastStack) {
            if ((toast as Toast).toastId === id)
                return toast;
        }
        return null;
    }

    containsToast(toast: Panel) {
        return this._toastStack?.includes(toast);
    }

    private _getNewSnackToastZIndex(): number {
        let zIndex = this.getHighestZIndex();
        if (zIndex == null)
            zIndex = this.getEffectiveZIndex();
        if (zIndex == null) {
            //this should only be true when we are adding a snack/toast to the root panel, which has no zIndex value,
            //when the root panel has no snacks/toasts present
            if (ArrayUtil.isEmptyArray(ScreenStack.getZIndexComponents()) === true)
                return ScreenStack.getNewHighestZIndex();
            return 1; //last resort fallback
        }
        return zIndex + 1;
    }

    getHighestZIndex(): number {
        let result = this.getEffectiveZIndex();
        if (this._snackStack != null) {
            for (const snack of this._snackStack) {
                if (snack.zIndex != null && (result == null || result < snack.zIndex))
                    result = snack.zIndex;
            }
        }
        if (this._toastStack != null) {
            for (const toast of this._toastStack) {
                if (toast.zIndex != null && (result == null || result < toast.zIndex))
                    result = toast.zIndex;
            }
        }
        return result;
    }

    getZIndexComponents(): Panel[] {
        const result: Panel[] = [];
        if (this.zIndex != null)
            result.push(this);
        if (this._snackStack != null) {
            for (const snack of this._snackStack) {
                if (snack.zIndex != null)
                    result.push(snack);
            }
        }
        if (this._toastStack != null) {
            for (const toast of this._toastStack) {
                if (toast.zIndex != null)
                    result.push(toast);
            }
        }
        return result;
    }

    get wrap(): boolean {
        return this._wrap;
    }

    set wrap(value: boolean) {
        if (value === this._wrap)
            return;
        this._wrap = value;
        if (this._rows != null) {
            for (const row of this._rows) {
                if (this.wrap === false)
                    row.style.flexWrap = "nowrap";
                else if (this.wrap === true)
                    row.style.flexWrap = "wrap";
            }
        }
    }
}

ComponentTypes.registerComponentType("panel", Panel.prototype.constructor);
