import { Alignment, ArrayUtil, Collection, DOMUtil, DynamicLoader, getLogger, getThemeForKey } from "@mcleod/core";
import { DataSourceMode, deserializeComponents, serializeComponents } from "../..";
import { Component } from "../../base/Component";
import { ComponentTypes } from "../../base/ComponentTypes";
import { ComponentUtil } from "../../base/ComponentUtil";
import { Container } from "../../base/Container";
import { ListenerListDef } from "../../base/ListenerListDef";
import { DomEvent } from "../../events/DomEvent";
import { EventListenerList } from "../../events/EventListenerList";
import { SelectionEvent, SelectionListener } from "../../events/SelectionEvent";
import { TabCloseEvent, TabCloseListener } from "../../events/TabCloseEvent";
import { Overlay } from "../../page/Overlay";
import { ComponentCreationCallback } from "../../serializer/ComponentDeserializer";
import { Button } from "../button/Button";
import { ButtonVariant } from "../button/ButtonVariant";
import { Panel } from "../panel/Panel";
import { Tab } from "./Tab";
import { AccordionDefaultExpand, TabStyle, TabsetPropDefinitions, TabsetProps } from "./TabsetProps";

const log = getLogger("components.tabset.Tabset");
const _beforeTabSelectionListenerDef: ListenerListDef = { listName: "_beforeTabSelectionListeners" };
const _afterTabSelectionListenerDef: ListenerListDef = { listName: "_afterTabSelectionListeners" };
const _beforeTabCloseListenerDef: ListenerListDef = { listName: "_beforeTabCloseListeners" };
const _afterTabCloseListenerDef: ListenerListDef = { listName: "_afterTabCloseListeners" };

/**
 * Explanation of of components inside:
 * _element is the main DOM element
 * _main adds _header and _content
 * _content is where the actual tab content is added as the user switches tabs
 * _header adds _tabContainer and _panelTools
 * _panelTools adds any tools that app code wants
 * _tabContainer adds _tabRow, _tabSel, and _outlinedTabBorderCover
 * _tabRow adds each Tab's heading Panel
 * _tabSel is the little underline that indicates which tab is selected (when in underlined/scroll mode)
 * _outlinedTabBorderCover is a little div that hides the border under the selected tab (when in outlined mode)
 */

export class Tabset extends Container implements TabsetProps {
    private _accordionDefaultExpand: AccordionDefaultExpand;
    private _allowStyleChange: boolean = true;
    private _selectedIndex: number;
    private _beforeTabSelectionListeners: EventListenerList;
    private _afterTabSelectionListeners: EventListenerList;
    private _beforeTabCloseListeners: EventListenerList;
    private _afterTabCloseListeners: EventListenerList;
    private _main: Panel;
    private _content: Panel;
    private _header: Panel;
    private _tabContainer: Panel;
    private _tabRow: Panel;
    private _panelTools: Panel;
    private _tabSel: Panel;
    private _accordionRoot: Panel;
    private _scrollRoot: Panel;
    private observer: IntersectionObserver;
    private _selectedIndexByClick: number = -1;
    private _tabStyle: TabStyle;
    private _configButton: Button;
    private _lastTabGrew: boolean;
    private _outlinedTabBorderCover: Panel;
    private buttonAddTab: Button;
    private _scrollMutationObserver: MutationObserver;
    private _scrollRootScrollTop: number;
    private _tabAlignment: Alignment;

    constructor(props: Partial<TabsetProps>) {
        super("div", props);
        this._beforeTabSelectionListeners = new EventListenerList(this, null);
        this._afterTabSelectionListeners = new EventListenerList(this, null);
        this._beforeTabCloseListeners = new EventListenerList(this, null);
        this._afterTabCloseListeners = new EventListenerList(this, null);
        this._selectedIndex = -1;
        this._main = new Panel({ id: "tabsetMain", fillHeight: true, padding: 0 });
        this._content = new Panel({ id: "tabsetContent", padding: 0, fillRow: true, fillHeight: true, scrollY: true, borderLeftColor: "subtle", borderTopColor: "subtle", borderBottomColor: "subtle", borderRightColor: "subtle" });
        this._panelTools = new Panel({ id: "tabsetTools", rowBreak: false, padding: 0 });
        this._configButton = new Button({ variant: ButtonVariant.round, imageName: "settings", color: "subtle.darker", tooltip: "Change the way this tabbed view looks", tooltipPosition: Alignment.LEFT, width: 16, height: 16, padding: 2, rowBreak: false });
        this._configButton.addClickListener(() => this.configClicked());
        this._panelTools.rowBreakDefault = false;
        this._header = new Panel({ id: "tabsetHeader", fillRow: true, rowBreak: false, padding: 0 });
        this._tabContainer = new Panel({ id: "tabsetTabContainer", fillRow: true, rowBreak: false, padding: 0, borderTopColor: "strokeSecondary", borderBottomColor: "strokeSecondary" });
        this._tabRow = new Panel({ id: "tabsetTabRow", fillRow: true, padding: 0, borderColor: "strokeSecondary" });
        this._accordionRoot = new Panel({ id: "accordionRoot", fillRow: true, padding: 0 });
        this._scrollRoot = new Panel({ id: "scrollRoot", fillRow: true, padding: 0 });
        this._tabSel = this._createTabSelectionIndicator();
        this._outlinedTabBorderCover = this._createOutlinedTabBorderCover();
        this.addTabsToMain();
        this.syncContentBorder();
        this.reLayout();
        this.setProps({ padding: 0, ...props });
        this.addMountListener(() => this._underlineSelectedScrollHeader(this.selectedIndex));
        this.addMountListener(() => this._moveOutlinedTabBorderCover(this.selectedIndex));
    }

    setProps(props: Partial<TabsetProps>) {
        super.setProps(props);
    }

    /**
     *
     * @param {*} tab
     * @param {*} tabLabelComponentOrProps This can be:
     *    - a function - will call that function (passing the tabset and the tab) and then will apply the below rules
     *    - null: will use the caption, image, and imageAlign of 'tab'
     *    - a string: will use a standard label with the passed value as the caption
     *    - a plain object: will use a standard label with the passed value as the props of the label
     *    - a component: will use the pass value as the tab
     * @returns
     */
    add(tab: Tab) {
        if (tab == null)
            return;
        log.debug("Add tab", tab);
        if (typeof tab === "function")
            tab = (tab as any)(this);
        tab.tabset = this;
        this.components.push(tab);
        tab.createHeading();
        tab._index = this.components.length - 1;
        if (this.tabStyle === TabStyle.ACCORDION)
            this._accordionRoot.add(tab.heading);
        else
            this._tabRow.add(tab.heading);
        if (this.tabStyle === TabStyle.SCROLL) {
            this._setDefaultScrollPadding(tab);
            this._scrollRoot.add(tab);
            this.observeTab(tab);
        }
        if (this.components.length === 1)
            this.selectedIndex = 0;
    }

    override insert(component: Component, index: number): Component {
        if (component instanceof Tab) {
            if (this._designer != null)
                this.selectedIndex = -1;
            this.components.splice(index, 0, component);
            this.resetTabIndexes();
            component.tabset = this;
            component.createHeading();
            if (this.tabStyle === TabStyle.ACCORDION) {
                let accordionRootIndex = 0;
                if (index > 0) {
                    const prevTab = this.getTab(index - 1);
                    accordionRootIndex = this.getAccordionRootIndex(prevTab) + 1;
                }
                this._accordionRoot.insert(component.heading, accordionRootIndex);
            }
            else
                this._tabRow.insert(component.heading, index);
            if (this.tabStyle === TabStyle.SCROLL) {
                this._setDefaultScrollPadding(component);
                this._scrollRoot.insert(component, index);
                this.observeTab(component);
            }
            if (this._designer != null)
                component?.heading?._element.click();
        }
        return component;
    }

    private getAccordionRootIndex(tab: Tab) {
        return Math.max(
            this._accordionRoot.components.indexOf(tab),
            this._accordionRoot.components.indexOf(tab.heading)
        );
    }

    private resetTabIndexes() {
        for (let i = 0; i < this.components.length; i++)
            this.getTab(i)._index = i;
    }

    private _setDefaultScrollPadding(tab: Tab) {
        if (tab.padding == null) {
            tab.padding = 16;
            if (tab.paddingTop == null)
                tab.paddingTop = 24;
        }
    }

    indexOf(tab: Tab) {
        return this.components.indexOf(tab);
    }

    reLayout() {
        this._selectedIndex = -1;
        this.syncContentBorder();
        this._tabContainer.removeAll();
        this._tabContainer.add(this._tabRow);
        this._tabContainer.add(this._tabSel);
        this._tabContainer.add(this._outlinedTabBorderCover);
        this._element.appendChild(this._main._element);
        this._main.parent = this;

        const oldTabs = [...this.components];
        this._main.removeAll();
        this._content.removeAll();
        this._accordionRoot.removeAll();
        this._scrollRoot.removeAll();

        const vert = this.isVertical();
        this._header.fillRow = !vert;
        this._header.rowBreak = !vert;
        this._tabContainer.rowBreak = vert;
        const align = this.tabAlignment;
        this._content.rowBreak = align !== Alignment.RIGHT;
        if (align === Alignment.LEFT || align === Alignment.TOP)
            this.addTabsToMain();
        this._main.add(this._content);
        if (align === Alignment.RIGHT || align === Alignment.BOTTOM)
            this.addTabsToMain();

        if (this.tabStyle === TabStyle.ACCORDION && !this._content.contains(this._accordionRoot))
            this._content.add(this._accordionRoot);
        else if (this.tabStyle !== TabStyle.ACCORDION && this._content.contains(this._accordionRoot))
            this._content.remove(this._accordionRoot);
        if (this.tabStyle === TabStyle.OUTLINED)
            this._header.backgroundColor = getThemeForKey("tabset.unselectedTab").backgroundColor;

        this._components = [];
        if (this.tabStyle !== TabStyle.ACCORDION) {
            this._tabRow.removeAll();
            this._content.removeAll();
        }
        if (this.tabStyle === TabStyle.SCROLL && !this._content.contains(this._scrollRoot)) {
            this._content.add(this._scrollRoot);
        }
        else if (this.tabStyle !== TabStyle.SCROLL && this._content.contains(this._scrollRoot)) {
            this._content.remove(this._scrollRoot);
        }

        this.unobserveAllTabs();
        for (const tab of oldTabs) {
            const tabAsTab = tab as Tab;
            if (!tabAsTab.expanded && this.tabStyle === TabStyle.ACCORDION)
                tab.height = "unset";
            tabAsTab.createTitle();
            this.add(tabAsTab);
            if (tabAsTab.expanded)
                tabAsTab.expandCollapseTab(true, false);
        }
    }

    public isVertical(): boolean {
        return this.tabAlignment === Alignment.LEFT || this.tabAlignment === Alignment.RIGHT;
    }

    private addTabsToMain() {
        this._main.add(this._header);
        this._header.removeAll();
        this._header.add(this._tabContainer);
        this._header.add(this._panelTools);
        if (this._designer != null && this.buttonAddTab != null)
            this._header.add(this.buttonAddTab);
    }

    public startMaintainScrollPosition() {
        if (this.tabStyle === TabStyle.SCROLL && this._content.contains(this._scrollRoot))
            this._setupScrollMutationObserver();
    }

    public stopMaintainScrollPosition() {
        this._removeScrollMutationObserver();
    }

    /**
     * Setup a mutation observer to watch changes within the scrollable area (only used when the tab style is SCROLL).
     * The goal is that, when a tab is selected and the user has scrolled down (isn't at the top of the scrollable area),
     * that we don't change what they can see when content is added/removed above the visible area.
     *
     * Example: when opening a movement, the stops list is the last thing that finishes loading.  Previously, if you selected a
     * tab below the stops list before it finished loading, the extra height added to the scrollable area would cause you to no
     * longer be looking at the tab you'd selected (you'd be looking at something higher up the page).  So in this example the mutation
     * observer would adjust the scrollTop of the scrollable area based on any height added to the page.
     */
    private _setupScrollMutationObserver() {
        if (this._scrollMutationObserver != null)
            this._scrollMutationObserver.disconnect();
        let oldScrollRootHeight = DOMUtil.getElementHeight(this._scrollRoot._element);
        let oldSelectedTabOffsetTop = null;
        this._scrollMutationObserver = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
            if (this.selectedIndex >= 0 && this._getScrollRootScrollTop() !== 0) {
                log.debug("Tabset observed mutations: %o", mutations);
                const selectedTabElement = this._getSelectedTabElement();
                if (oldSelectedTabOffsetTop == null)
                    oldSelectedTabOffsetTop = selectedTabElement.offsetTop;
                const tabTopDiff = selectedTabElement.offsetTop - oldSelectedTabOffsetTop;
                if (this._scrollRootScrollTop == null)
                    this._scrollRootScrollTop = Math.max(this._getScrollRootScrollTop(), selectedTabElement.offsetTop);
                const newScrollRootHeight = DOMUtil.getElementHeight(this._scrollRoot._element);
                const scrollAreaHeightDiff = newScrollRootHeight - oldScrollRootHeight;
                log.debug("Scroll mutation observer update: old height %o  new height %o  diff %o  scrollTop %o", oldScrollRootHeight, newScrollRootHeight, tabTopDiff, this._scrollRootScrollTop);
                if (scrollAreaHeightDiff !== 0 && tabTopDiff !== 0) {
                    this._scrollRootScrollTop += tabTopDiff;
                    this._alterScrollRootScrollTop(this._scrollRootScrollTop);
                    oldScrollRootHeight = DOMUtil.getElementHeight(this._scrollRoot._element);
                    oldSelectedTabOffsetTop = selectedTabElement.offsetTop;
                }
            }
            else
                oldScrollRootHeight = DOMUtil.getElementHeight(this._scrollRoot._element);
        });
        this._scrollMutationObserver.observe(this._scrollRoot._element.parentElement, { childList: true, subtree: true });
    }

    private _removeScrollMutationObserver() {
        if (this._scrollMutationObserver == null)
            return;
        this._scrollMutationObserver.disconnect();
        this._scrollMutationObserver = null;
    }

    private _getSelectedTabElement(): HTMLElement {
        return this.components[this.selectedIndex]._element;
    }

    scrollToTab(tab: Tab, maintainScrollPositionTime?: number) {
        this._selectedIndexByClick = tab._index;

        if (maintainScrollPositionTime != null) {
            this.startMaintainScrollPosition();
            setTimeout(() => this.stopMaintainScrollPosition(), maintainScrollPositionTime);
        }
        //seed the 'find scrollable parent' element with scrollRoot, because we know we want the see the tab within some parent of scrollRoot
        //(tabsetContent or its child panelRow).  without this, scrollRoot can sometimes be found as the scrollable parent, which is bad because
        //scrollRoot is much bigger than the visible area.  this in turn causes scrollIntoView to do nothing, which is bad.
        //this only happened to work before because scrollRoot's clientHeight and scrollHeight were the same in most cases.  however, scrolling all the way
        //to the bottom of the tabset caused scrollRoot's scrollHeight to grow, so it could then be found as the scrollable parent.
        log.debug("Scrolling Tabset - scroll root height before scroll: %o", this._scrollRoot._element.clientHeight)
        tab.scrollIntoView({ smooth: true }, this._scrollRoot._element);
    }

    private _getScrollRootScrollTop(): number {
        return this._scrollRoot._element.parentElement.scrollTop;
    }

    private _alterScrollRootScrollTop(value: number) {
        this._scrollRoot._element.parentElement.scrollTop = value;
    }

    private createObserver() {
        if (this.observer == null)
            this.observer = new IntersectionObserver((entries) => this.handleIntersection(entries), { threshold: [0.1, 0.3, 0.6] });
    }

    private destroyObserver() {
        if (this.observer == null)
            return;
        this.observer.disconnect();
        this.observer = null;
    }

    private observeTab(tab: Tab) {
        if (this.isDeserializing() === true || this.tabStyle !== TabStyle.SCROLL)
            return;
        this.createObserver();
        this.observer.observe(tab._element);
    }

    private unobserveTab(tab: Tab) {
        this.observer?.unobserve(tab._element);
    }

    private observeAllTabs() {
        this.unobserveAllTabs();
        for (const tab of this.components) {
            this.observeTab(tab as Tab);
        }
    }

    private unobserveAllTabs() {
        for (const tab of this.components) {
            this.unobserveTab(tab as Tab)
        }
        this.destroyObserver();
    }

    private handleIntersection(entries) {
        //if the user selected the index by clicking, we don't want the observer do anything, the right tab will already be underlined
        if (this._selectedIndexByClick === this._selectedIndex) {
            this._underlineSelectedScrollHeader();
        }

        //find the most visible component (if there are two, pick the first one), and select that one
        let firstMostVisible = ComponentUtil.getFirstMostVisibleComponent(this.components, this._content, 0.6);
        if (firstMostVisible == null) {
            firstMostVisible = this.components[0];
        }
        const selectedIndex = this._getSelectedIndex(firstMostVisible._element);

        if (this._selectedIndexByClick !== -1) {
            if (selectedIndex === this._selectedIndexByClick) {
                this._selectedIndexByClick = -1;
            }
            return;
        }

        if (selectedIndex !== -1 && this.selectedIndex !== selectedIndex) {
            (this.components[selectedIndex] as Tab).select();
            return;
        }
        else if (this.selectedIndex === selectedIndex) {
            this._underlineSelectedScrollHeader();
            return;
        }
    }

    public refreshUnderline() {
        this._underlineSelectedScrollHeader();
    }

    private _underlineSelectedScrollHeader(selectedIndex: number = this.selectedIndex) {
        const wasVisible = this._tabSel.visible;
        const couldBeVisible = this.isDeserializing() !== true &&
            (this.isVertical() || [null, undefined, TabStyle.UNDERLINED, TabStyle.SCROLL].includes(this.tabStyle)) &&
            selectedIndex >= 0;
        this._tabSel._element.style.transition = (wasVisible === true && couldBeVisible === true) ? "left 0.25s,top 0.25s" : "";
        if (couldBeVisible === true) {
            const label = (this.components[selectedIndex] as Tab).heading._element;
            if (this.isVertical()) {
                this._tabSel.height = label.offsetHeight - 1;
                this._tabSel.width = 2;
                this._tabSel.left = 0;
                this._tabSel.top = label.offsetTop;
            } else {
                this._tabSel.width = label.offsetWidth;
                this._tabSel.left = label.offsetLeft - this._tabContainer._element.offsetLeft;
                this._tabSel.top = label.offsetTop + label.offsetHeight;
            }
            //don't set the tab selector underline to be visible until we have positioned in its first position
            if (wasVisible === false && DOMUtil.getStyleAttrAsNumber(this._tabSel.top) > 0)
                this._tabSel.visible = true;
        }
    }

    private _moveOutlinedTabBorderCover(selectedIndex: number = this.selectedIndex) {
        this._outlinedTabBorderCover.visible = this.tabStyle === TabStyle.OUTLINED && selectedIndex >= 0;
        if (this._outlinedTabBorderCover.visible)
            this.updateOutlinedTabBorderCover(selectedIndex);
    }

    private updateOutlinedTabBorderCover(index: number) {
        const label = (this.components[index] as Tab).heading._element;
        const leftBorderWidth = DOMUtil.convertStyleAttrToNumber(label.style.borderLeftWidth);
        const rightBorderWidth = DOMUtil.convertStyleAttrToNumber(label.style.borderRightWidth);
        const topBorderWidth = DOMUtil.convertStyleAttrToNumber(label.style.borderTopWidth);
        const bottomBorderWidth = DOMUtil.convertStyleAttrToNumber(label.style.borderBottomWidth);
        const cover = this._outlinedTabBorderCover;
        cover.style.position = "absolute";
        cover.zIndex = this.getEffectiveZIndex() + 1;
        if (this.isVertical()) {
            cover.top = label.offsetTop + 1;
            cover.width = 1;
            cover.height = label.offsetHeight - 2;
            if (this.tabAlignment === Alignment.LEFT) {
                cover.right = 0;
                cover.width = 4;
            }
            else
                cover.left = -1;
        } else {
            cover.left = label.offsetLeft - this._tabContainer._element.offsetLeft + leftBorderWidth;
            cover.width = label.offsetWidth - leftBorderWidth - rightBorderWidth;
            cover.height = 1;
            if (this.tabAlignment === Alignment.TOP)
                cover.bottom = -1;
            else
                cover.top = -1;
        }
    }

    dataSourceModeChanged(mode: DataSourceMode): void {
        super.dataSourceModeChanged(mode);
        for (const tab of this.components) {
            tab.dataSourceModeChanged(mode);
        }
    }

    private _getSelectedIndex(target: Element): number {
        for (let x = 0; x < this.components.length; x++)
            if (this.components[x]._element === target)
                return x;
        return -1;
    }

    remove(tab: Tab) {
        const index = this.indexOf(tab);
        if (index >= 0)
            this.removeAt(index, null);
    }

    removeAt(index: number, domEvent: DomEvent) {
        if (index < 0 || index >= this._components.length)
            return;
        let targetIndex = this._selectedIndex;
        if (this.selectedIndex >= this._components.length - 1)
            targetIndex -= 1;
        const tab = this.components[index] as Tab;
        const beforeCloseEvent = this._beforeTabCloseListeners.fireListeners(() => new TabCloseEvent(tab, domEvent));
        if (beforeCloseEvent != null && beforeCloseEvent.defaultPrevented)
            return;
        if (this.selectedIndex >= this._components.length - 1)
            this._selectedIndex = targetIndex;
        this._components.splice(index, 1);
        for (let i = index; i < this.components.length; i++)
            this.getTab(i)._index--;
        this._tabRow.removeAt(index);
        if (this.tabStyle === TabStyle.SCROLL) {
            this._scrollRoot.remove(tab);
            this.unobserveTab(tab);
        } else if (this.tabStyle === TabStyle.ACCORDION) {
            tab.expandCollapseTab(false, false);
            this._accordionRoot.remove(tab.heading);
        }
        this._afterTabCloseListeners.fireListeners(() => new TabCloseEvent(tab, domEvent));
        this._setSelectedInternal(this._selectedIndex, true, null);
    }

    swap(tab1: Tab, tab2: Tab) {
        ArrayUtil.switchArrayElements(this._components, this.indexOf(tab1), this.indexOf(tab2));
        this.reLayout();
    }

    _handleSpecialSwitch(tab: Component, by: number) {
        if (this._designer != null && tab instanceof Tab) {
            const otherTab = this.getTab(tab._index + by);
            if (otherTab != null) {
                if (tab.expanded = true)
                    tab.expandCollapseTab(false, false);
                this._selectedIndex = -1;
                this.swap(tab, otherTab);
                tab.heading._element.click();
                return true;
            }
        }
        return false;
    }

    /**
     * Tabset tools are components that are placed after (to the right on horizontal Tabsets or under
     * on vertical Tabsets) the tabs.  That is usually empty space and there is often a desire to put
     * Buttons or other Components there.  addTool() is used to add those Components.
     * @param component
     */
    addTool(component: Component) {
        this._panelTools.add(component);
    }

    _createTabSelectionIndicator() {
        const result = new Panel({
            id: "tabSel",
            backgroundColor: "primary",
            padding: 0,
            height: 2,
            visible: false
        });
        result._element.style.position = "absolute";
        return result;
    }

    private _createOutlinedTabBorderCover() {
        const result = new Panel({
            id: "outlinedTabBorderCover",
            backgroundColor: "white",
            padding: 0,
            height: 1,
            visible: false,
            zIndex: this.getEffectiveZIndex() + 1
        });
        result._element.style.position = "relative";
        return result;
    }

    getTab(index: number): Tab {
        return this.getComponent(index) as Tab;
    }

    getActiveTab(): Tab {
        const sel = this.selectedIndex;
        if (sel == null || sel < 0)
            return null;
        return this.getTab(sel);
    }

    get selectedIndex() {
        return this._selectedIndex;
    }

    set selectedIndex(value) {
        if (value === this._selectedIndex && this.tabStyle !== TabStyle.SCROLL)
            return;
        this._setSelectedInternal(value, true, null);
    }

    _setSelectedInternal(value: number, fireChange: boolean, domEvent: DomEvent) {
        const oldValue = this._selectedIndex;
        let component = (this.getComponent(value) as Tab);
        if (component != null && component.getComponentCount() != null && component.getComponentCount() === 1)
            component = (component.components[0] as Tab);
        if (fireChange) {
            const event = new SelectionEvent(this, this.components[value], value, this.components[oldValue], oldValue, domEvent);
            this._beforeTabSelectionListeners.fireListeners(event);
            if (event.defaultPrevented)
                return;
        }
        if (this._selectedIndex >= 0)
            this.getTab(this._selectedIndex).selected = false;
        if (value >= 0 && value < this._components.length)
            this.getTab(value).selected = true;
        if (this.tabStyle !== TabStyle.ACCORDION && this.tabStyle !== TabStyle.SCROLL) {
            if (this._content.getComponentCount() > 0)
                this._content.removeAt(0);
            this._selectedIndex = value;
            if (value >= 0) {
                if (this.isVertical()) {
                    this._outlinedTabBorderCover.visible = value >= 0;
                    this.updateOutlinedTabBorderCover(value);
                    this.refreshUnderline();
                }
                else if (this.tabStyle === TabStyle.UNDERLINED)
                    this.refreshUnderline();
                else if (this.tabStyle === TabStyle.OUTLINED) {
                    this._outlinedTabBorderCover.visible = value >= 0;
                    this.updateOutlinedTabBorderCover(value);
                }
                this._content.add(this.components[value]);
                if (this._designer != null)
                    this._designer.selectComponent(this.components[value]);
            }
        }
        if (value >= 0 && this.tabStyle === TabStyle.SCROLL) {
            this._selectedIndex = value;
            this.refreshUnderline();
        }
        this._tabSel.visible = this._tabSel.visible && this._components.length > 0;

        const event = new SelectionEvent(this, this.components[this._selectedIndex], this._selectedIndex, this.components[oldValue], oldValue, domEvent);
        this._afterTabSelectionListeners.fireListeners(event);
    }

    override _serializeNonProps(): string {
        let result = "\"components\": " + serializeComponents(this.components, null) + ",\n";
        if (this.tools.length > 0)
            result += "\"tools\": " + serializeComponents(this.tools, null) + ",\n";
        return result;
    }

    _deserializeSpecialProps(componentOwner, compDef, defaultPropValues, dataSources, componentCreationCallback: ComponentCreationCallback) {
        if (compDef.tools != null) {
            const children = deserializeComponents(componentOwner, compDef.tools, this._designer, defaultPropValues, dataSources, componentCreationCallback);
            for (let i = 0; i < children.length; i++)
                this.addTool(children[i]);
        }
        if (compDef.components != null) {
            const children = deserializeComponents(componentOwner, compDef.components, this._designer, { tabStyle: compDef["tabStyle"], ...defaultPropValues }, dataSources, componentCreationCallback);
            for (let i = 0; i < children.length; i++)
                this.add(children[i]);
        }
        return ["tools", "components"];
    }

    protected _getDefaultEventProp(): string {
        return "afterTabSelection";
    }

    public addBeforeTabSelectionListener(value: SelectionListener) {
        this.addEventListener(_beforeTabSelectionListenerDef, value);
    }

    public removeBeforeTabSelectionListener(value: SelectionListener) {
        this.removeEventListener(_beforeTabSelectionListenerDef, value);
    }

    public addAfterTabSelectionListener(value: SelectionListener) {
        this.addEventListener(_afterTabSelectionListenerDef, value);
    }

    public removeAfterTabSelectionListener(value: SelectionListener) {
        this.removeEventListener(_afterTabSelectionListenerDef, value);
    }

    public addBeforeTabCloseListener(value: TabCloseListener) {
        this.addEventListener(_beforeTabCloseListenerDef, value);
    }

    public removeBeforeTabCloseListener(value: TabCloseListener) {
        this.removeEventListener(_beforeTabCloseListenerDef, value);
    }

    public addAfterTabCloseListener(value: TabCloseListener) {
        this.addEventListener(_afterTabCloseListenerDef, value);
    }

    public removeAfterTabCloseListener(value: TabCloseListener) {
        this.removeEventListener(_afterTabCloseListenerDef, value);
    }

    _designerDrop(component: Tab) {
        this.add(component);
    }

    get _designer() {
        return super._designer;
    }

    set _designer(value: any) {
        super._designer = value;
        if (value != null && value.addDesignerContainerProperties != null)
            value.addDesignerContainerProperties(this, 400, 100, tool => tool === "Tab");
        if (value?.allowsDrop) {
            this.buttonAddTab = new Button({
                imageName: "add",
                color: "primary",
                variant: ButtonVariant.round,
                tooltip: "Add a new tab to this tabset",
                margin: 0,
                marginTop: -16
            });
            this.buttonAddTab.addClickListener(event => this.addDesignerTab());
            this._panelTools._designer = value;
            if (value != null)
                this._header.add(this.buttonAddTab);
        }
    }

    private addDesignerTab(): void {
        const designer = this._designer as any;
        const tab = designer.addTool("tab", this);
        tab.owner = this;
        tab.fillHeight = true;
        tab.heading?._element.click();
    }

    protected override _initialDropInDesigner(): void {
        this.addDesignerTab();
    }

    get tools(): Component[] {
        return this._panelTools.components;
    }

    set tools(value: Component[]) {
        this._panelTools.removeAll();
        for (let i = 0; value != null && i < value.length; i++)
            this.addTool(value[i]);
    }

    usingScrollStyle(): boolean {
        return this.tabStyle === TabStyle.SCROLL;
    }

    get tabStyle(): TabStyle {
        return this._tabStyle != null ? this._tabStyle : TabStyle.UNDERLINED;
    }

    set tabStyle(value: TabStyle) {
        if (this._tabStyle === value)
            return;
        if (this.selectedIndex > 0)
            this._selectedIndex = 0;

        this._tabStyle = value;
        for (const tab of this.components as Array<Tab>)
            tab.tabStyle = value;
        this._tabSel.visible = this._tabSel.visible && this.selectedIndex >= 0 && (value === TabStyle.UNDERLINED || value === TabStyle.SCROLL);
        this._outlinedTabBorderCover.visible = this.selectedIndex >= 0 && value === TabStyle.OUTLINED;
        if (value === TabStyle.UNDERLINED || value === TabStyle.SCROLL)
            this._tabRow.setProps({ paddingLeft: 0 });
        if (value === TabStyle.ACCORDION)
            this._tabRow.removeAll();
        if (value === TabStyle.OUTLINED) {
            this._tabRow.setProps({ paddingLeft: 0 });
            if (this._panelTools != null)
                this._panelTools.setProps({
                    paddingLeft: 0,
                });
        }
        else {
            this._tabRow.setProps({ paddingLeft: 0, borderBottomWidth: 0 });
            if (this._panelTools != null)
                this._panelTools.setProps({ paddingLeft: 0, borderBottomWidth: 0 });
        }
        if (value === TabStyle.SCROLL)
            this._header.setProps({ borderTopWidth: 1, borderBottomWidth: 1, borderShadow: true });
        else
            this._header.setProps({ borderTopWidth: 0, borderBottomWidth: 0, borderShadow: false });
        this.reLayout();
        this._setSelectedInternal(this._selectedIndex, false, null);
        if (this.components?.length > 0 && this.selectedIndex >= 0) {
            if (value === TabStyle.SCROLL)
                (this.components[this.selectedIndex] as Tab).expanded = true;
            this._expandDefaultAccordionTabs();
        }
    }

    private _expandDefaultAccordionTabs() {
        if (this.tabStyle !== TabStyle.ACCORDION)
            return;
        //remove tabs from the accordion root before we decide which should be visible
        //this is necessary because we may pass through this method > 1 time, and we don't want to add tabs twice
        for (let x = 0; x < this._accordionRoot.components.length;) {
            const comp = this._accordionRoot.components[x];
            if (comp instanceof Tab)
                this._accordionRoot.components.splice(x, 1);
            else
                x++;
        }
        if (this.components?.length === 0)
            return;
        const expandSetting = this.accordionDefaultExpand;
        if (expandSetting === AccordionDefaultExpand.NONE) {
            for (const tab of this.components) {
                (tab as Tab).expandCollapseTab(false, false);
            }
            return;
        }
        if (expandSetting === AccordionDefaultExpand.FIRST) {
            let isFirst = true;
            for (const tab of this.components) {
                (tab as Tab).expandCollapseTab(isFirst, false);
                isFirst = false;
            }
            return;
        }
        if (expandSetting === AccordionDefaultExpand.ALL) {
            for (const tab of this.components) {
                (tab as Tab).expandCollapseTab(true, false);
            }
            return;
        }
    }

    get accordionDefaultExpand(): AccordionDefaultExpand {
        return this._accordionDefaultExpand != null ? this._accordionDefaultExpand : AccordionDefaultExpand.FIRST;
    }

    set accordionDefaultExpand(value: AccordionDefaultExpand) {
        this._accordionDefaultExpand = value;
        this._expandDefaultAccordionTabs();
    }

    get allowStyleChange(): boolean {
        return this._allowStyleChange;
    }

    set allowStyleChange(value: boolean) {
        this._allowStyleChange = value;
        if (value && !this._main.contains(this._configButton))
            this._header.insert(this._configButton, this._main.indexOf(this._content));
        else if (!value)
            this._header.remove(this._configButton);
    }

    configClicked() {
        const configClass = DynamicLoader.getClassForPath("components/page/TabsetConfigPanel");
        const panel = new configClass({ selectedStyle: this.tabStyle, tabset: this });
        panel.width = null; // prevents alignToAnchor from making this the same width as the button
        Overlay.alignToAnchor(panel, this._configButton, Alignment.RIGHT, Alignment.BOTTOM);
        Overlay.showInOverlay(panel);
    }

    _afterDeserialize() {
        this.observeAllTabs();
        const event = new SelectionEvent(this, this.components[this._selectedIndex], this._selectedIndex, null, -1);
        this._afterTabSelectionListeners.fireListeners(event);
    }

    override getPropertyDefinitions() {
        return TabsetPropDefinitions.getDefinitions();
    }

    isLastTab(tab: Tab): boolean {
        return tab._index === this.components.length - 1;
    }

    getContentHeight(): number {
        return DOMUtil.getElementHeight(this._content._element);
    }

    getContentHeightString(): string {
        return DOMUtil.getElementHeightString(this._content._element);
    }

    override get serializationName() {
        return "tabset";
    }

    expandAll() {
        this._setAllExpanded(true);
    }

    private _setAllExpanded(expanded: boolean) {
        if (this.tabStyle === TabStyle.ACCORDION || this.tabStyle === TabStyle.SCROLL) {
            for (let i = 0; i < this.components.length; ++i) {
                if (this.components[i] instanceof Tab)
                    (this.components[i] as Tab).expanded = expanded;
            }
        }
    }

    collapseAll() {
        this._setAllExpanded(false);
    }

    growLastTab(tab: Tab): boolean {
        if (log.isDebugEnabled()) {
            log.debug("Scrolling Tabset - scroll root client height before grow: %o", this._scrollRoot._element.clientHeight);
            log.debug("Scrolling Tabset - scroll root scroll height before grow: %o", this._scrollRoot._element.scrollHeight);
            const p1 = DOMUtil.findScrollableParent(this._scrollRoot._element);
            log.debug("Scrolling Tabset - parent scroll height before grow %o", p1?.scrollHeight);
            log.debug("Scrolling Tabset - parent client height before grow %o", p1?.clientHeight);
        }
        if (this.tabStyle !== TabStyle.SCROLL || this.isLastTab(tab) !== true || this._lastTabGrew === true)
            return false;
        tab.height = this.getContentHeightString();
        if (log.isDebugEnabled()) {
            log.debug("Scrolling Tabset - scroll root client height after grow: %o", this._scrollRoot._element.clientHeight);
            log.debug("Scrolling Tabset - scroll root scroll height after grow: %o", this._scrollRoot._element.scrollHeight);
            const p2 = DOMUtil.findScrollableParent(this._scrollRoot._element);
            log.debug("Scrolling Tabset - parent scroll height after grow %o", p2?.scrollHeight);
            log.debug("Scrolling Tabset - parent client height after grow %o", p2?.clientHeight);
        }
        this._lastTabGrew = true;
        return true;
    }

    override getListenerDefs(): Collection<ListenerListDef> {
        return {
            ...super.getListenerDefs(),
            "beforeTabSelection": { ..._beforeTabSelectionListenerDef },
            "afterTabSelection": { ..._afterTabSelectionListenerDef },
            "beforeTabClose": { ..._beforeTabCloseListenerDef },
            "afterTabClose": { ..._afterTabCloseListenerDef }
        };
    }

    public get tabAlignment(): Alignment {
        return this._tabAlignment || Alignment.TOP;
    }

    public set tabAlignment(value: Alignment) {
        this._tabAlignment = value;
        this.syncContentBorder();
        if (this.buttonAddTab != null)
            this.buttonAddTab.marginTop = this.isVertical() ? 0 : -16;
        for (const tab of this.components)
            (tab as Tab).tabAlignment = value;
        this.reLayout();
        this._setSelectedInternal(this._selectedIndex, false, null);
    }

    private syncContentBorder() {
        const align = this.tabAlignment;
        const outlined = this.tabStyle === TabStyle.OUTLINED;
        this._content.borderTopWidth = align === Alignment.TOP && outlined ? 1 : 0;
        this._content.borderBottomWidth = align === Alignment.BOTTOM && outlined ? 1 : 0;
        if (align == Alignment.LEFT)
            this._header.style.boxShadow = "2px 1px 3px 0px rgb(0 0 0 / 20%)";
        this._content.borderRightWidth = align === Alignment.RIGHT && outlined ? 1 : 0;
    }

    public override discoverIncludedComponents(): Component[] {
        return [...super.discoverIncludedComponents(), this._panelTools];
    }
}

ComponentTypes.registerComponentType("tabset", Tabset.prototype.constructor);
