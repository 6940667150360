import { CommonDialogs } from "@mcleod/common";
import {
    BlurEvent, Button, ChangeEvent, ClickEvent, Component, CrudDecorator, DataDisplayEvent, DataSource,
    DataSourceAction, DataSourceExecutionEvent, DataSourceMode, DataSourceModeChangeEvent, Dialog, EditRowDecorator,
    Label, Layout, LookupModelSearchEvent, McLeodMainPageUtil, Overlay, Panel, SaveButton, SlideoutDecorator, Snackbar,
    Switch, Tab, Table, TableAddRowResult, TableContentsChangedEvent, TableRow, TableRowBeforeSaveEvent,
    TableRowDisplayEvent, TableRowMode, Textbox, Toast, ValidationResult
} from "@mcleod/components";
import { DataSourceValidationEvent } from "@mcleod/components/src/events/DataSourceValidationEvent";
import { TableAction } from "@mcleod/components/src/events/TableContentsChangedEvent";
import {
    Alignment, Api, DisplayType, getAuthSettings, getLogger, HorizontalAlignment, McLeodClassicIntegration, ModelRow,
    Navigation, PermissionsUtil, StringUtil, VerticalAlignment, WindowTitle
} from "@mcleod/core";
import { SuccessFail } from "@mcleod/core/src/SuccessFail";
import { ThemeCommonPage } from "@mcleod/core/src/themes/common/ThemeCommonPage";
import { Orders } from "@mcleod/dispatch/src/Orders";
import { ModelMovement } from "@mcleod/dispatch/src/models/ModelMovement";
import { ModelOrders } from "@mcleod/dispatch/src/models/ModelOrders";
import { UsersResponsibilitySlideoutTrans } from "@mcleod/general/src/UsersResponsibilitySlideoutTrans";
import { getDispatchControlBoolean } from "@mcleod/general/src/models/ModelDispatchControl";
import { apptConfirmCheck } from "../../dispatch/src/AppointmentUtil";
import { CheckDuplicateBOL, DuplicateBOLSource } from "../../dispatch/src/CheckDuplicateBOL";
import { DuplicateOrderPrompt } from "../../dispatch/src/DuplicateOrderPrompt";
import { PostToLoadBoards } from "../../dispatch/src/PostToLoadBoards";
import { RearrangeStopSlideout } from "../../dispatch/src/RearrangeStopSlideout";
import { RecurringOrderPrompt } from "../../dispatch/src/RecurringOrderPrompt";
import { VoidOrderPrompt } from "../../dispatch/src/VoidOrderPrompt";
import { CompareTender } from "./CompareTender";
import { DeclineTender } from "./DeclineTender";
import { EdiOrderOverview } from "./EdiOrderOverview";
import { EdiOrderRates } from "./EdiOrderRates";
import { EdiStop } from "./EdiStop";
import { MakeOrderUtility } from "./MakeOrderUtility";
import { OrderLoadTenderHistory } from "./OrderLoadTenderHistory";
import { TransmissionStatusQuickInfo } from "./TransmissionStatusQuickInfo";
import { AutogenLayoutViewEdiOrder } from "./autogen/AutogenLayoutViewEdiOrder";
import { ModelEdiOrder, RowEdiOrder } from "./models/ModelEdiOrder";
import { ModelEdiorderProfile, RowEdiorderProfile } from "./models/ModelEdiorderProfile";

import { EDIOrderProfileQuickInfo } from "./EdiOrderProfileQuickInfo";
import { EdiStopProgress } from "./EdiStopProgress";
import { LinkTenderDialog } from "./LinkTenderDialog";
import { PurposeSwap } from "./PurposeSwap";
import { PurposeSwapDialog } from "./PurposeSwapDialog";

import { BrltlUtil } from "@mcleod/dispatch/BrltlUtil";
import { ModelDataChangeType } from "@mcleod/components/src/databinding/DataSource";
import { OrderHandlingRequirementsSlideout } from "@mcleod/dispatch/src/OrderHandlingRequirementsSlideout";
import { ModelOtherChargeEdi } from "./models/ModelOtherChargeEdi";
import { RateUtil } from "@mcleod/dispatch/src/RateUtil";

const log = getLogger("lme.datafusion.ViewEdiOrder");

const viewOrderURL = "lme/dispatch/Orders?mode=update&key=";
const equipmentTypeOptionsMap = {
    tarps: "T",
    expedited: "E"
};


export class ViewEdiOrder extends AutogenLayoutViewEdiOrder {
    private _doAfterAction: () => void = () => this.mainDataSource.search({ id: this.mainDataSource.activeRow.get("id") });
    private _postOrderAction: string | (() => void);
    private _viewOrderURL = "lme/dispatch/Orders?mode=update&key=";
    private viewOrderListURL = "lme/dispatch/OrderList?ids=";
    private _assignCarrierURL = "assignCarrier";
    private _findCarrierURL = "lme/dispatch/Movement?mode=update&key=";
    private newOrderURL = "lme/dispatch/Orders";
    //private creditValidator: OrderCreditValidator;
    private checkDuplicateBOL: CheckDuplicateBOL;
    private _invokedFromEdi: boolean = false;
    private loadingRecurringOrder: boolean = false;
    private _isBrokerageLtl: boolean = false;
    private _profile: RowEdiorderProfile;
    private _ediOrderRow: RowEdiOrder;
    private _ellipsisMenu: Label[] = [];

    override onLoad(): void {
        const mPactConfigured = getAuthSettings().dispatch_control[0].target_pay_calc == "M" &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_smart_api_key) &&
            !StringUtil.isEmptyString(getAuthSettings().dispatch_control[0].mpact_host_url);

        this.textboxEquipmentTypeIdGeneral.onSelectItem = () => {
            this.textboxEquipmentTypeIdEquipment.text = this.textboxEquipmentTypeIdGeneral.text;
            if (mPactConfigured) {
                this.layoutRates.calculateRates(false, false);
            }
            return undefined;
        };

        this.tabFA.visible = false;

        this.textboxEquipmentTypeIdEquipment.onSelectItem = () => {
            this.textboxEquipmentTypeIdGeneral.text = this.textboxEquipmentTypeIdEquipment.text;
            if (mPactConfigured) {
                this.layoutRates.calculateRates(false, false);
            }
            return undefined;
        };

        this.textboxCommodityId.addChangeListener(event => {
            this.commodityCodeChanged();
        });

        this.textboxRevenueCodeId.onSelectItem = () => {
            if (this.textboxRevenueCodeId.getFirstLookupModelData()?.get("id") != null)
                this.revenueCodeChanged(this.textboxRevenueCodeId.getFirstLookupModelData().get("id"));
            return undefined;
        }

        this.textboxOrderType.onSelectItem = () => {
            if (this.textboxOrderType.getFirstLookupModelData()?.get("id") != null) {
                this.orderTypeChanged(this.textboxOrderType.getFirstLookupModelData().get("id"));
            }
            return undefined;
        }

        this.textboxCustomerId.onSelectItem = () => {
            const custId = this.textboxCustomerId.getFirstLookupModelData()?.get("id");
            if (custId != null) {
                (this.layoutOverview as EdiOrderOverview).customerChanged(this.textboxCustomerId.getFirstLookupModelData());
                //this.creditValidator.customerId = custId;
            }

            return undefined;
        }

        //this.addRecurringOrderListener();

        const userfilter = event => event.filter.web_user_type = "U";
        this.textboxEnteredUserId.addBeforeLookupModelSearchListener(userfilter);

        if (history.state != null && history.state.state?.successAdd == true)
            Toast.showSuccessToast("Yay! Your order was created successfully!", "Order number " + history.state.state?.orderId);

        this.stopsTable.onEditLayoutLoaded = this.stopTableOnEditLoaded();

        this.stopsTable.allowDelete = false;
        this.mainDataSource.addAfterModeChangeListener(event => {
            if (this.mainDataSource.mode == DataSourceMode.SEARCH) {
                this.stopsTable.dataSource = null;
                this.textboxOrderedDate.displayType = DisplayType.DATERANGE;
            }
            else if (this.stopsTable.dataSource == null)
                this.stopsTable.dataSource = this.sourceEdiStop;
        })

        this.textboxOrderedBy.addBeforeLookupModelSearchListener(event => {
            event.filter.parent_row_id = this.mainDataSource.activeRow?.get("customer_id");
            event.filter.parent_row_type = "C";
        });

        this.textboxOrderedBy.onSelectItem = ((textbox, selection) => {
            this.mainDataSource.activeRow.set("ordered_by_id", (selection as ModelRow).get("id"))
            return undefined;
        });

        this.layoutRates.checkPickupAndDeliveryEntered = () => {
            const stopData = this.sourceEdiStop.data;
            if (stopData.length < 2) return false;
            const pickupEntered = stopData[0].get("stop_type") === "PU";
            const deliveryEntered = stopData[stopData.length - 1].get("stop_type") === "SO";
            return pickupEntered && deliveryEntered;
        }

        this.setDispatchSettings();
        this.mainDataSource.componentValidationFailedCallback = (result: ValidationResult) => this.onComponentValidationFailed(result);
        this.sourceViewEdiOrder.afterGetDataboundValues = (row: ModelRow, dataSource: DataSource) => this.sourceOrdersAfterGetDataboundValues(row, dataSource);

        if (this.displayingFromToolbox === true) {
            this.postOrderAction = this.displayOrderCreatedToast;
            this.dataHeader.showSaveAndClose = true;
            this.dataHeader.showSave = false;
        }
        this.styleTab(this.tabErrors);

        this.layoutOverview.imageInboundTenderError.addClickListener(event => {
            this.tabsetAddOrders.scrollToTab(this.tabTenderInfo);
            this.tabMapErrors.expandAll();
        });
        this.layoutOverview.imageOutboundTenderError.addClickListener(event => {
            this.tabsetAddOrders.scrollToTab(this.tabTenderInfo);
            this.tabMapErrors.expandAll();
        });
        this.layoutOverview.imageReplyError.addClickListener(event => {
            this.tabsetAddOrders.scrollToTab(this.tabReply);
            this.layoutReply.tabErrors.tabset.expandAll();
        });

        this.labelOrderId.addDataDisplayListener(event => {
            const orderId= this.mainDataSource.activeRow?.get("order_id");
            const originalOrder = this.mainDataSource.activeRow?.get("original_order");
            if(!StringUtil.isEmptyString(orderId) && "Y" === originalOrder){
                this.toggleEditFields(false);
            }
        });

    }

    onDirectionDisplay(event: DataDisplayEvent) {
        if (this.mainDataSource.activeRow?.get("direction") != "O") {
            this.panelOutbound.visible = false;
            this.textboxCustomerId.enabled = StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id"));
            this.layoutRates.buttonRate.enabled =StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id"))
            this.checkboxNoDisplay.visible = true;
            this.checkboxNoDisplay.enabled = StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id"));
        }
        else {
            this.textboxCustomerId.enabled = false;
            this.layoutRates.buttonRate.enabled = false;
            this.checkboxNoDisplay.visible = false;
            this.checkboxNoDisplay.enabled = false;
        }
    }

    async setTitle() {
        WindowTitle.set("View Tender " + this.sourceViewEdiOrder.activeRow?.get("id"));
        const orderId = this.sourceViewEdiOrder.activeRow?.get("order_id");
        const recordInUse = this.sourceViewEdiOrder.activeRow?.get("record_locked");
        const overview = this.layoutOverview as EdiOrderOverview;

        if (orderId != null) {
            this.labelOrderNumber.visible = true;
            this.labelOrderId.visible = true;
            this.imageGoToOrder.visible = true;
            this.labelOrderStatus.visible = true;


            const rowOrder = await new ModelOrders().searchSingle({ id: orderId }, "lme/dispatch/Orders");
            const orderStatus = rowOrder.get("status");
            if (orderStatus == "A") {
                this.labelOrderStatus.caption = "Available";
            } else if (orderStatus == "D") {
                this.labelOrderStatus.caption = "Delivered";
            } else if (orderStatus == "V") {
                this.labelOrderStatus.caption = "Void";
            } else if (orderStatus == "P") {
                this.labelOrderStatus.caption = "In Progress";
            }

            const moveId = rowOrder.get("curr_movement_id");
            const rowMovement = await new ModelMovement().searchSingle({ id: moveId }, "lme/dispatch/Movement");
            const moveStatus = rowMovement.get("status");
            if (moveStatus == "A") {
                overview.labelMoveStatus.caption = "Available";
            } else if (moveStatus == "D") {
                overview.labelMoveStatus.caption = "Delivered";
            } else if (moveStatus == "V") {
                overview.labelMoveStatus.caption = "Void";
            } else if (moveStatus == "P") {
                overview.labelMoveStatus.caption = "In Progress";
            }

        }
        else {
            this.labelOrderNumber.visible = false;
            this.labelOrderId.visible = false;
            this.imageGoToOrder.visible = false;
            this.labelOrderStatus.visible = false;


            overview.labelMoveStatus.visible = false;
            overview.moveLabel.visible = false;
            overview.buttonMoveDetails.visible = false;
        }

        if (recordInUse == "Y") {
            this.buttonLock.visible = true;
        } else {
            this.buttonLock.visible = false;
        }
    }

    unlockTender() {
        const tenderId = this.sourceViewEdiOrder.activeRow?.get("id");

        CommonDialogs.showYesNo("Are you sure you want to unlock this tender [ID: " + tenderId + "]?").then(async response => {
            if (response) {
                this.buttonLock.busy = true;
                if (tenderId != null) {
                    const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: tenderId });
                    const locked = ediOrderRow.get("record_in_use", "");
                    if (locked == "Y") {
                        ediOrderRow.set("record_in_use", "N");
                        ediOrderRow.post();
                        await this.delay(3000).then(() => {
                            log.info("Delay done.  Refreshing.");
                            this.sourceViewEdiOrder.search({ id: tenderId });
                        });
                    }
                }
                this.buttonLock.busy = false;
            }
        });
    }

    async delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    viewOrder() {
        const orderId = this.sourceViewEdiOrder.activeRow?.get("order_id");
        if (orderId != null)
            Navigation.navigateTo(this.viewOrderUrl + orderId, { newTab: true });
    }

    layoutOverviewOnDataDisplay(event) {

    }

    private onComponentValidationFailed(result: ValidationResult): void {
        const comp = result.component;
        if (comp?.owner instanceof EdiStop)
            comp.owner.onComponentValidationFailed(result);
    }

    public override getDataHeaderEllipsisActions(): Label[] {
        if (this.mainDataSource?.activeRow == null)
            return this._ellipsisMenu;

        const direction = this.mainDataSource.activeRow.get("direction");
        const ediOrderRow = this.mainDataSource.activeRow;
        const replyAction = this.mainDataSource.activeRow.get("reply_action");
        const purposeType = this.mainDataSource.activeRow.get("purpose_type");

        if(direction == "I") {
            if(this.replyEligibleToTransmit()) {  //tender requires a reply based on partner/purpose
                if(this.needsReply()) {  //batch is still 0
                    if(StringUtil.isEmptyString(replyAction)) {
                        this._ellipsisMenu.push(new Label({ caption: "Accept Tender", wrap: false, onClick: (event) => this.acceptTender(ediOrderRow) }));
                        this._ellipsisMenu.push(new Label({ caption: "Decline Tender", wrap: false, onClick: (event) => this.declineTender(event) }));
                    } else {
                        this._ellipsisMenu.push(new Label({ caption: "Transmit Reply", wrap: false, onClick: (event) => this.manualTransmitReply() }));
                        if(replyAction == "A") {
                            this.addPurposeActions(purposeType, ediOrderRow, true);
                        }
                    }
                } else {  //batch is present
                    this._ellipsisMenu.push(new Label({ caption: "Retransmit Reply", wrap: false, onClick: (event) => this.manualTransmitReply() }));
                    if(replyAction == "A") {
                        this.addPurposeActions(purposeType, ediOrderRow, false);
                    }
                }
            } else {  //reply not required
                this.addPurposeActions(purposeType, ediOrderRow, false);
            }
        } else {
            this._ellipsisMenu.push(new Label({ caption: "Retransmit Tender", wrap: false, onClick: (event) => this.manualTransmitReply() }));
        }

        return this._ellipsisMenu;
    }

    addPurposeActions(purposeType: string, ediOrderRow: RowEdiOrder, needsReply: boolean) {
        if(StringUtil.isEmptyString(purposeType)) return;
        const orderId = this.mainDataSource.activeRow.get("order_id");

        switch(purposeType) {
            case "O":
                if(StringUtil.isEmptyString(orderId))this._ellipsisMenu.push(new Label({ caption: "Make Order", wrap: false, onClick: async (event) => await this.createOrder() }));
                break;
            case "U":
                this._ellipsisMenu.push(new Label({ caption: "Compare Order", wrap: false, onClick: async (event) => await this.compareTender(ediOrderRow, needsReply) }));
                break;
            case "C":
                const tenderId = this.mainDataSource.activeRow.get("id");
                this._ellipsisMenu.push(new Label({ caption: "Cancel Order", wrap: false, onClick: async (event) => await this.voidOrderAuto(orderId, tenderId, needsReply) }));
                break;
        }
    }

    needsReply(): boolean {
        const replyBatch = this.mainDataSource.activeRow.get("reply_batch_id");
        if (replyBatch == "0" && this.replyEligibleToTransmit()) {
            return true;
        } else {
            return false;
        }
    }

    replyEligibleToTransmit(): boolean {
        const purposeType = this.mainDataSource.activeRow.get("purpose_type");
        const reqReplyOrig = this.mainDataSource.activeRow.get("reply_req_original");
        const reqReplyChange = this.mainDataSource.activeRow.get("reply_req_change");
        const reqReplyCancel = this.mainDataSource.activeRow.get("reply_req_cancel");

        if (purposeType == "O" && reqReplyOrig == "Y" || purposeType == "U" && reqReplyChange == "Y" || purposeType == "C" && reqReplyCancel == "Y") {
            return true;
        } else {
            return false;
        }
    }

    replyRetransmittable(): boolean {
        const replyBatch = this.mainDataSource.activeRow.get("reply_batch_id");

        if (replyBatch > "0" && this.replyEligibleToTransmit()) {
            return true;
        } else {
            return false;
        }
    }

    async findOrderId(ediOrderRow): Promise<any> {
        const partnerId = ediOrderRow.get("partner_id");
        const shipmentId = ediOrderRow.get("shipment_id");
        const version = ediOrderRow.get("version");
        const tenderId = ediOrderRow.get("id");

        return await Api.search("lme/datafusion/find-order-id", { partner_id: partnerId, shipment_id: shipmentId, version: version, tender_id: tenderId });
    }

    async sendReply(ediOrderId: string): Promise<any> {
        log.info("Replying to tender " + ediOrderId + ".");
        return await Api.post("lme/datafusion/accept-tender", {
            edi_order_id: ediOrderId
        }).then((response) => {
            const batch = response.data[0].reply_batch;
            const description = response.data[0].description;

            if (batch != "-1") {
                //Toast.showSuccessToast("Tender replied to successfully with batch " + batch + ".");
                return Promise.resolve(response);
            } else {
                //Snackbar.showWarningSnackbar("Unable to send reply.  " + description);
                return Promise.reject(response);
            }

        });
    }

    async manualTransmitReply(): Promise<any> {
        const ediOrderId = this.mainDataSource?.activeRow?.get("id");

        log.info("Transmitting reply for tender  " + ediOrderId + ".");
        return await Api.post("lme/datafusion/transmit-single-reply", {
            edi_order_id: ediOrderId
        }).then((response) => {
            const batch = response.data[0].reply_batch;
            const description = response.data[0].description;

            this.mainDataSource.search({ "id": ediOrderId });

            if (batch != "-1" && batch != "0") {
                Toast.showSuccessToast("Tender reply transmitted successfully with batch " + batch + ".");
                return Promise.resolve(response);
            }
            else {
                Snackbar.showWarningSnackbar("Unable to transmit reply.  " + description);
                return Promise.reject(response);
            }

        }).catch((error) => {
            log.error("Unable to transmit reply.  " + error);
        });
    }

    async declineTender(event: ClickEvent) {
        const dt = new DeclineTender();
        dt.ediOrderRow = this.mainDataSource.activeRow;
        dt.decline(() => this.refresh());
    }

    refresh(tenderId?:string): void {
        if(tenderId) {
            this.mainDataSource.search({ id: tenderId });
        } else {
            this.mainDataSource.search({ id: this.mainDataSource?.activeRow.get("id") });
        }
    }

    private refreshEllipsisMenu() {
        while (this._ellipsisMenu.length) {
            this._ellipsisMenu.pop();
        }
    }

    async voidOrderAuto(orderId: string, ediOrderId: string, needsReply: boolean, callback?): Promise<any> {
        return new Promise(async (resolve) => {
            const orderRowStatus = await this.getOrderRowStatus(orderId);
            const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });
            const replyBatch = ediOrderRow.get("reply_batch_id");

            if (orderRowStatus == "V") {
                if (needsReply && replyBatch == "0") {
                    const prompt = "Order is already voided.  Would you still like to send the reply?";
                    const voidDialog = this.buildCustomYesNoDialog("Void Tender", prompt, "Yes", "No", 490);
                    voidDialog.addUnmountListener(() => {
                        resolve(true);
                    });
                    voidDialog.show().then(async yes => {
                        if (yes) {
                            this.acceptLoadTender(ediOrderRow.get("id")).then(result => {
                                //this.processReplyStatus(orderId, result, "already voided");
                            });
                        }
                        resolve(true);
                    });
                }
                else {
                    await CommonDialogs.showMessage("Order is already voided.  Removing from Tender Express.").then(() => {
                        this.excludeExistingTender(ediOrderRow, orderId);
                        resolve(true);
                    });
                }
            }
            else {
                Api.post("lme/datafusion/df-void-order", { order_id: orderId }).then(async result => {
                    const status = result.data[0].new_status;
                    const text = result.data[0].text;

                    if (status == "V") {
                        if (needsReply) {
                            await this.acceptLoadTender(ediOrderId).then(response => {
                                const batch = response.data[0].reply_batch;

                                if (batch != "-1" && batch != "0") {
                                    Toast.showSuccessToast("Order " + orderId + " successfully voided.  Reply sent with batch " + batch + ".");
                                }
                                else {
                                    Snackbar.showWarningSnackbar("Order " + orderId + " successfully voided.  Unable to send reply.");
                                }
                                resolve(true);
                            });
                        }
                        else {
                            Toast.showSuccessToast("Order " + orderId + " successfully voided.");
                            resolve(true);
                        }
                    }
                    else {
                        if (needsReply) {
                            CommonDialogs.showYesNo("Order " + orderId + " not voided.  " + text + "\nWould you still like to accept the tender?", "Reply to Tender",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                    if (yes) {
                                        await this.acceptLoadTender(ediOrderId).then(response => {
                                            const batch = response.data[0].reply_batch;

                                            if (batch != "-1" && batch != "0") {
                                                Toast.showSuccessToast("Reply sent with batch " + batch + ".");
                                            }
                                            else {
                                                const error = response.data[0].description;
                                                Snackbar.showWarningSnackbar("Unable to send reply.  " + error);
                                            }
                                        });
                                    }
                                    resolve(true);
                                });
                        }
                    }
                });
            }
        });
    }

    async excludeExistingTender(ediOrderRow: RowEdiOrder, orderId: string) {
        ediOrderRow.set("no_display", "Y");
        await ediOrderRow.post();
        return;
    }

    async getOrderRowStatus(orderId: string): Promise<string> {
        const rowOrder = await new ModelOrders().searchSingle({ id: orderId }, "lme/dispatch/Orders");
        const status = rowOrder.get("status", "");
        return status;
    }

    async findEdiOrderRow(ediOrderId: string): Promise<RowEdiOrder> {
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: ediOrderId });
        return ediOrderRow;
    }

    async findEdiOrderProfile(ediOrderRow: RowEdiOrder): Promise<RowEdiorderProfile> {
        const profile = await new ModelEdiorderProfile().searchSingle({ partner_id: ediOrderRow.data["partner_id"], version: ediOrderRow.data["version"], direction: ediOrderRow.data["direction"] });
        return profile;
    }

    async acceptTender(ediOrderRow): Promise<any> {
        if (this.mainDataSource?.activeRow == null)
            return;

        const ediOrderId = this.mainDataSource.activeRow.get("id");
        const orderId = this.mainDataSource.activeRow.get("order_id");
        const recordInUse = this.mainDataSource.activeRow.get("record_in_use");

        if (recordInUse == "Y") {
            CommonDialogs.showError("Record is currently locked.  Cannot reply.");
            return;
        }
        const ediOrderProfile = await this.findEdiOrderProfile(ediOrderRow);
        this.mainDataSource.setComponentsBusy(true);
        //Original - make order before replying
        //TODO refactor with createOrder
        if (ediOrderRow.get("purpose_type") == "O") {
            await Api.search("lme/datafusion/make-order", {
                "edi_order_id": ediOrderId, "calc_max_target": true
            }).then(async response => {
                const orderLayout = Layout.getLayout("lme/dispatch/Orders") as Orders;
                orderLayout.invokedFromEdi = true;
                orderLayout.imageAddHandlingReqOnClick = () => {
                    if( orderLayout.sourceBrltlOrderHdrXFgp.data.length == 0 && orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data.length >0){
                        orderLayout.sourceBrltlOrderHdrXFgp.data = orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data;
                        orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.clear();
                        orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.setProp("Y", "madeFromEdi", null);
                    }
                    OrderHandlingRequirementsSlideout.showSlideout({
                        hdrDataSource: orderLayout.sourceBrltlOrderHdrXFgp,
                        hdrChanged: (row: ModelRow, type: ModelDataChangeType) => orderLayout.hdrChanged(row, type),
                        onSave: (hasChanged: boolean) => {
                            orderLayout.layoutHandlingReqs.populateHdrs(orderLayout.sourceBrltlOrderHdrXFgp.data, true, hasChanged).then(() => {
                                orderLayout.sourceBrltlOrderHdrXFgp.notifyHasChangedComponents(true);
                            });
                        },
                        invokedFromEdi: true
                    })
                };
                const orderPanel = new CrudDecorator({
                    layout: orderLayout,
                    providedApiData: response.data[0].order,
                    mode: DataSourceMode.ADD,
                    headerProps: {
                        showClose: true,
                        showSaveAndClose: true,
                        showSave: true,
                        showExecute: true,
                        onClose: () => {
                            orderPanel.slideOut();
                        },
                        onSaveButtonClose: () => {orderPanel.slideOut(); },
                        onExecute: () => {
                            const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                            const origDestRateId = orderPanel.layout.mainDataSource.activeRow.get("orig_dest_rate_id");
                            const commitmentId = orderPanel.layout.mainDataSource.activeRow.get("commitment_id");
                            MakeOrderUtility.addHandlingRequirements(orderId, orderLayout);
                            Api.post("lme/datafusion/after-create-order-tasks", {
                                "edi_order_id": ediOrderId,
                                "order_id": orderId,
                                "orig_dest_rate_id": origDestRateId,
                                "commitment_id": commitmentId
                            }).then(async () => {
                                const rate: string = orderPanel.layout.mainDataSource.activeRow.get("rate");

                                if (!StringUtil.isEmptyString(rate)) {
                                    if (parseFloat(rate) != ediOrderRow.get("rate")) {
                                        orderPanel.layout.mainDataSource.activeRow.post(); //repost so rates recalculate
                                    }
                                }
                                this.mainDataSource.mode = DataSourceMode.UPDATE;
                                this.dataHeader.showClose = false;
                                this.dataHeader.showSave = false;
                                this.toggleEditFields(false);
                            }).catch(error => {
                                log.debug(error);
                                CommonDialogs.showServerError(error);
                            });
                        }
                    },
                    doAfterSlideOut: async () => {
                        const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                        if (!StringUtil.isEmptyString(orderId)) {
                            await this.acceptLoadTender(ediOrderId).then(response => {
                                if (response != null) {
                                    const batch = response.data[0].reply_batch;
                                    const description = response.data[0].description;

                                    if (batch != "-1") {
                                        Toast.showToast(this.getResultsToastPanel(orderId, description, "created"), null, { millisUntilDismissal: 7000 });
                                    }
                                    else {
                                        const message = "Order " + orderId + " created.  Unable to send reply.  " + description;
                                        Snackbar.showWarningSnackbar(message);
                                    }
                                }
                                else {
                                    const message = "Order " + orderId + " created.  Unable to send reply.";
                                    Snackbar.showWarningSnackbar(message);
                                }

                                this.sourceViewEdiOrder.search({ id: ediOrderId });

                            }).catch(error => {
                                const message = "Order " + orderId + " created.  Unable to send reply.";
                                Snackbar.showWarningSnackbar(message);
                            });
                        }
                        else {
                            CommonDialogs.showYesNo("Order not created.  Would you still like to accept the tender?", "Reply to Tender",
                                { titleProps: { imageName: "circleX2", imageColor: "primary.light" } }).then(async yes => {
                                    if (yes) {
                                        await this.acceptLoadTender(ediOrderId).then(response => {
                                            if (response != null) {
                                                const batch = response.data[0].reply_batch;

                                                if (batch != "-1" && batch != "0") {
                                                    const message = "Order not created.  Reply sent with batch " + batch + ".";
                                                    Toast.showSuccessToast(message);
                                                    const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                                }
                                                else {
                                                    const error = response.data[0].description;
                                                    const message = "Order not created.  Unable to send reply.  " + error;
                                                    Snackbar.showWarningSnackbar(message);
                                                    const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                                }
                                            }
                                            else {
                                                const message = "Unable to send reply.";
                                                Snackbar.showWarningSnackbar(message);
                                                const newRow = "N/A" + "," + ediOrderId + "," + "O" + "," + message;
                                            }
                                            this.refresh();
                                            this.mainDataSource.mode = DataSourceMode.UPDATE;
                                            this.dataHeader.showClose = false;
                                            this.dataHeader.showSave = false;
                                        });
                                    }
                                    else {
                                        ediOrderRow.set("record_in_use", "N");
                                        ediOrderRow.post();
                                    }
                                }).catch(async () => {
                                    ediOrderRow.set("record_in_use", "N");
                                    ediOrderRow.post();
                                });
                        }
                    },
                    layoutLoadListeners: [() => {
                        const l = orderPanel.layout as Orders;
                        l.switchRecurringOrder.visible = false;
                        l.buttonEnterNewOrder.visible = false;
                        l.panelHowManyOrders.visible = false;
                        l.postOrderAction = "";
                        l.tabNextActions.visible = true;
                        l.mainDataSource.setProp(true, "isEdi", null);
                        l.mainDataSource.setProp(ediOrderProfile.get("id"), "ediProfileId", null);
                         l.imageAddHandlingReq.addClickListener( () => {
                            l.sourceBrltlOrderHdrXFgp.data = l.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data;
                            l.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.setProp("Y", "madeFromEdi", null);
                        });
                        l.postOrderNavigateTo = () => {
                            if (l.postOrderAction == l.viewOrderUrl) {
                                const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                                return Navigation.navigateTo(l.postOrderAction + orderId, { newTab: true });
                            }
                            else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.findCarrierURL) {
                                const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                                return Navigation.navigateTo(l.postOrderAction + key, { newTab: false }, { findCarrier: true });
                            }
                            else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.assignCarrierURL) {
                                const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                                return Navigation.navigateTo(l.findCarrierURL + key, { newTab: false }, { assignCarrier: true, isEdi: true });
                            }
                            else {
                                return Promise.resolve();
                            }
                        }
                    }]
                });
                orderPanel.slideIn({ speed: 200 });
                this.mainDataSource?.activeRow.set("record_in_use", "N");

            }).catch(reason => {
                log.info("Exception making order.  " + reason);
                Snackbar.showWarningSnackbar("Error encountered creating order : " + reason);
            }).finally(() => {
                this.mainDataSource.setComponentsBusy(false);
            });

        }
        //Change - compare order before replying
        else if (ediOrderRow.get("purpose_type") == "U") {
            if (!StringUtil.isEmptyString(orderId)) {
                log.info("Comparing tender [id: " + ediOrderId + "]");
                const compare = new CompareTender();
                this.dataHeader.showClose = false;
                this.dataHeader.showSaveAndClose = true;
                this.saveButton.busy = false;
                this.saveButton.enabled = true;

                await compare.compareTender(ediOrderRow, true, this).then(() => {
                    this.saveButton.busy = false;
                    this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                    this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                    this.mainDataSource.search({ id: ediOrderRow.get("id") });
                });
            }
            else if (StringUtil.isEmptyString(orderId)) {
                const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" });
                dialog.ediOrderRow = ediOrderRow;

                dialog.show().then(async response => {
                    if (response) {
                        log.info("Comparing tender [id: " + ediOrderId + "]");
                        const compare = new CompareTender();

                        await compare.compareTender(ediOrderRow, true, this).then(() => {
                            this.saveButton.busy = false;
                            this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                            this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                            this.mainDataSource.search({ id: ediOrderRow.get("id") });
                        });
                    }
                    else {
                        log.info("User chose not to compare tender [id: " + ediOrderId + "]");
                        this.saveButton.busy = false;
                        this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                        this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                    }
                });
            }
        }
        //Cancel - void order before replying
        else if (ediOrderRow.get("purpose_type") == "C") {
            this.dataHeader.showSaveAndClose = true;
            if (!StringUtil.isEmptyString(orderId)) {
                CommonDialogs.showYesNo("Are you sure you want to void this order [ID: " + orderId + "]?").then(async response => {
                    if (response) {
                        await this.voidOrderAuto(orderId, ediOrderId, true).then(() => {
                            this.sourceViewEdiOrder.search({ id: ediOrderId });
                        });
                    }
                    else {
                        CommonDialogs.showYesNo("Would you still like to accept the tender?").then(async response => {
                            if (response) {
                                log.info("Accepting tender [id: " + ediOrderId + "]");
                                this.acceptLoadTender(ediOrderId).then(result => {
                                    this.saveButton.busy = false;
                                    this.sourceViewEdiOrder.search({ id: ediOrderId });
                                    this.mainDataSource.search({ id: ediOrderRow.get("id") });
                                });
                            }
                        });
                    }
                });
            }
            else {
                log.info("Order ID not on tender.  Looking for related tenders...");

                await this.findOrderId(ediOrderRow).then(result => {
                    const lookupOrderId = (result.data.length > 0) ? result.data[0].order_id : "";

                    if (!StringUtil.isEmptyString(lookupOrderId)) {
                        this.voidOrderAuto(lookupOrderId, ediOrderId, true);
                    }
                    else {
                        const prompt = "The following tender does not match an existing Order.  Manual action may be necessary " +
                            "to void any related Order.\n\n" +
                            "Bill of Lading: " + ediOrderRow.get("blnum") + "\n" +
                            "Shipment ID: " + ediOrderRow.get("shipment_id") + "\n" +
                            "Tender ID: " + ediOrderRow.get("id") + "\n\n" +
                            "Do you wish to exclude this tender?";
                        const dlg = this.buildCustomYesNoDialog("Reply to Tender", prompt, "Yes", "No", 490) as Dialog;
                        dlg.show().then(async yes => {
                            if (yes) {
                                if (ediOrderRow.get("no_display") != "Y") {
                                    log.info("Excluding edi_order with id [" + ediOrderId + "]");
                                    ediOrderRow.set("no_display", "Y");
                                    ediOrderRow.set("record_in_use", "N");
                                    await ediOrderRow.post().then(async () => {
                                        log.info("Starting delay (3s)...")
                                        await this.delay(3000).then(() => {
                                            log.info("Delay done.  Refreshing.")
                                            this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                                            this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                                        });
                                    });
                                }
                            }
                            else {
                                log.info("Unlocking edi_order with id [" + ediOrderId + "]");
                                ediOrderRow.set("record_in_use", "N");
                                await ediOrderRow.post().then(async () => {
                                    log.info("Starting delay (3s)...")
                                    await this.delay(3000).then(() => {
                                        log.info("Delay done.  Refreshing.")
                                        this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                                        this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                                    });
                                });
                            }

                            await this.acceptLoadTender(ediOrderId).then(async response => {
                                const batch = response.data[0].reply_batch;
                                Toast.showSuccessToast("Reply sent with batch " + batch + ".");
                                await this.delay(2000).then(() => {
                                    log.info("Delay done.  Refreshing.")
                                    this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                                    this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                                });
                            });

                        });
                    }
                });
            }
        }
    }

    async createOrder(): Promise<any> {
        const ediOrderId = this.mainDataSource.activeRow?.get("id");
        const direction = this.mainDataSource.activeRow?.get("direction");
        const ediOrderRow = this.mainDataSource.activeRow;
        const modelEdiOrderRow = await this.findEdiOrderRow(ediOrderId);
        const ediOrderProfile = await this.findEdiOrderProfile(ediOrderRow);
        if ("N" == modelEdiOrderRow.get("ignore_map_errors") && 0 < modelEdiOrderRow.get("error_count")[0]) {
            Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has errors that must be reviewed prior to order creation.");
            return;
        }
        if (await this.hasInvalidChargeCodes(ediOrderId, direction)) {
            Snackbar.showWarningSnackbar("Load tender " + ediOrderId + " has invalid/missing other charge codes which must be corrected before creating an order.");
            return;
        }
        //TODO refactor with accept tender
        await Api.search("lme/datafusion/make-order", {
            "edi_order_id": ediOrderId, "calc_max_target": true
        }).then(async response => {
            const orderLayout = Layout.getLayout("lme/dispatch/Orders") as Orders;
            orderLayout.invokedFromEdi = true;
            orderLayout.imageAddHandlingReqOnClick = () => {
                if( orderLayout.sourceBrltlOrderHdrXFgp.data.length == 0 && orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data.length >0){
                    orderLayout.sourceBrltlOrderHdrXFgp.data = orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.data;
                    orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.clear();
                    orderLayout.layoutHandlingReqs.sourceBrltlOrderHdrXFgp.setProp("Y", "madeFromEdi", null);
                }
                OrderHandlingRequirementsSlideout.showSlideout({
                    hdrDataSource: orderLayout.sourceBrltlOrderHdrXFgp,
                    hdrChanged: (row: ModelRow, type: ModelDataChangeType) => orderLayout.hdrChanged(row, type),
                    onSave: (hasChanged: boolean) => {
                        orderLayout.layoutHandlingReqs.populateHdrs(orderLayout.sourceBrltlOrderHdrXFgp.data, true, hasChanged).then(() => {
                            orderLayout.sourceBrltlOrderHdrXFgp.notifyHasChangedComponents(true);
                        });
                    },
                    invokedFromEdi: true
                })
            };
            const orderPanel = new CrudDecorator({
                layout: orderLayout,
                providedApiData: response.data[0].order,
                mode: DataSourceMode.ADD,
                headerProps: {
                    showClose: true,
                    showSaveAndClose: true,
                    showSave: true,
                    showExecute: true,
                    onClose: () => {
                        orderPanel.slideOut();
                    },
                    onSaveButtonClose: () => {
                        orderPanel.layout.mainDataSource.activeRow.post();
                        orderPanel.slideOut();
                    },
                    onExecute: () => {
                        const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                        const origDestRateId = orderPanel.layout.mainDataSource.activeRow.get("orig_dest_rate_id");
                        const commitmentId = orderPanel.layout.mainDataSource.activeRow.get("commitment_id");
                        MakeOrderUtility.addHandlingRequirements(orderId, orderLayout);

                        Api.post("lme/datafusion/after-create-order-tasks", {
                            "edi_order_id": ediOrderId,
                            "order_id": orderId,
                            "orig_dest_rate_id": origDestRateId,
                            "commitment_id": commitmentId
                        }).then(async () => {
                            const rate: string = orderPanel.layout.mainDataSource.activeRow.get("rate");

                            if (!StringUtil.isEmptyString(rate)) {
                                if (parseFloat(rate) != ediOrderRow.get("rate")) {
                                    orderPanel.layout.mainDataSource.activeRow.post(); //repost so rates recalculate
                                }
                            }
                            this.mainDataSource.mode = DataSourceMode.UPDATE;
                            this.dataHeader.showClose = false;
                            this.dataHeader.showSave = false;
                            this.toggleEditFields(false);
                            if (!StringUtil.isEmptyString(orderId)) {
                                Toast.showToast(this.getResultsToastPanel(orderId, "", "successfully created"), null, { millisUntilDismissal: 7000 });
                                this.refresh(ediOrderId);
                            }
                        }).catch(error => {
                            log.debug(error);
                            CommonDialogs.showServerError(error);
                        });
                    }
                },
                doAfterSlideOut: async () => {
                    this.refresh();
                    const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                    if (!StringUtil.isEmptyString(orderId)) {
                        Toast.showToast(this.getResultsToastPanel(orderId, "", "successfully created"), null, { millisUntilDismissal: 7000 });
                    }
                },
                layoutLoadListeners: [() => {
                    const l = orderPanel.layout as Orders;
                    l.switchRecurringOrder.visible = false;
                    l.buttonEnterNewOrder.visible = false;
                    l.panelHowManyOrders.visible = false;
                    l.postOrderAction = "";
                    l.tabNextActions.visible = true;
                    l.mainDataSource.setProp(true, "isEdi", null);
                    l.mainDataSource.setProp(ediOrderProfile.get("id"), "ediProfileId", null);
                    l.postOrderNavigateTo = () => {
                        if (l.postOrderAction == l.viewOrderUrl) {
                            const orderId = orderPanel.layout.mainDataSource.activeRow.get("id");
                            return Navigation.navigateTo(l.postOrderAction + orderId, { newTab: true });
                        }
                        else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.findCarrierURL) {
                            const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                            return Navigation.navigateTo(l.postOrderAction + key, { newTab: false }, { findCarrier: true });
                        }
                        else if (!StringUtil.isEmptyString(l.postOrderAction) && l.postOrderAction === l.assignCarrierURL) {
                            const key = orderPanel.layout.mainDataSource.activeRow?.get("curr_movement_id");
                            return Navigation.navigateTo(l.findCarrierURL + key, { newTab: false }, { assignCarrier: true, isEdi: true });
                        }
                        else {
                            return Promise.resolve();
                        }
                    }
                }]
            });
            orderPanel.slideIn({ speed: 200 });

        }).catch(reason => {
            log.info("Exception making order.  " + reason);
            Snackbar.showWarningSnackbar("Error encountered creating order : " + reason);
        });
    }
    async acceptLoadTender(ediOrderId: string): Promise<any> {
        return new Promise(async (resolve) => {
            resolve(await this.sendReply(ediOrderId));
        });
    }

    private _rearrangeStops() {
        const slideout = new RearrangeStopSlideout("O", this.mainDataSource?.activeRow.get("id"));
        slideout.showSlideout((canceled: boolean) => {
            if (!canceled)
                location.reload();
        });
    }


    textboxCustomerIdOnBlur(event: BlurEvent) {
        // if (event.changedWhileFocused && this.mainDataSource.activeRow.isNull("customer_id"))
        //   this.creditValidator.customerId = null;
        if ((this.mainDataSource.mode == DataSourceMode.ADD || this.mainDataSource.mode == DataSourceMode.UPDATE) && !this.mainDataSource.activeRow.isNull("customer_id")) {
            this.stopsTable.rows?.forEach(tableRow => {
                const orderStop = tableRow["orderStop"] as EdiStop;
                orderStop.textboxLocationId.googlePlacesProps.customerId = this.mainDataSource.activeRow?.get("customer_id");
            });
        }
    }

    async checkStopsForDistanceChange() {
        const stopdata = this.sourceEdiStop.data;
        let changedText = this.sourceEdiStop.deletedData?.length > 0 ? "removed from" : null;
        for (let i = 0; changedText == null && i < stopdata?.length; i++) {
            if (stopdata[i]._appending) changedText = "added to";
            if (stopdata[i].getChangedData()?.city_id != null) changedText = "modified on";
        }
        const locked = this.activeRow?.getBoolean("lock_miles", false)
        if (changedText != null) {
            if (locked) {
                const caption = `A stop has been ${changedText} this order which may affect mileage.  Would you like to unlock the mileage?`;
                const unlock = await CommonDialogs.showYesNo(caption, "Unlock Miles");
                if (unlock) {
                    this.activeRow.set("lock_miles", "N");
                    this.activeRow.set("bill_distance", null);
                }
            } else {
                this.activeRow.set("bill_distance", null);
            }
        }
    }

    public loadFromApiData(apiData: any, mode: DataSourceMode) {
        const ratesLayout = this.layoutRates as EdiOrderRates;
        try {
            ratesLayout.calculatingCharges = true;
            this.removeChildDataSources();
            //const order = new ModelRow("lme/dispatch/orders", true, apiData);
            const order = new ModelRow("lme/datafusion/view-edi-order", true, apiData);
            //set the stop data first so that when we set the order dataSource mode, we can not load the default 2 stops
            this.loadChildTableData(this.stopsTable, this._getArrayAndNull(order, "stops"), mode);
            this.mainDataSource.setRowsAndMode(mode, [order], () => this._displayDataCallback(this.mainDataSource));
            this.loadChildTableData(this.tableEquipmentRequirements, this._getArrayAndNull(order, "equip_match_details"), mode);
            this.loadChildTableData(ratesLayout.tableOtherCharges, this._getArrayAndNull(order, "other_charges"), mode);
            this.showHazmatWarning(this.mainDataSource.activeRow.get("hazmat_code") != null ? "Y" : "N");
            RateUtil.setAutoRateContext(this.layoutRates, this.mainDataSource.activeRow.get("rate_id"));
        }
        finally {
            ratesLayout.calculatingCharges = false;
        }
    }

    private _displayDataCallback(dataSource: DataSource) {
        dataSource.boundComponents.forEach(component => {
            if (component.field != "recurring_order_id")
                dataSource.displayDataInBoundComponent(component);
        });
        //this.creditValidator.customerId = this.mainDataSource.activeRow.get("customer_id");
        (this.layoutRates as EdiOrderRates).setMaxTargetPayData();
    }

    private _getArrayAndNull(row: ModelRow, field: string): ModelRow[] {
        const result = row.get(field) || [];
        row.set(field, null);
        return result;
    }

    removeChildDataSources() {
        this.stopsTable.rows?.forEach(tableRow => {
            const orderStop = tableRow["orderStop"] as EdiStop;
            if (orderStop != null) {
                orderStop.commentsAndRefNbrs.sourceEdiStopNote.parentDataSource = null;
                orderStop.commentsAndRefNbrs.sourceEdiRefNumber.parentDataSource = null;
            }
        });
    }

    private loadChildTableData(table: Table, data: any[], mode: DataSourceMode) {
        const tableDataSource = table.dataSource;
        tableDataSource.data = [];
        const modelRows = [];
        data.forEach((element: any) => modelRows.push(new ModelRow(tableDataSource.url, true, element)));
        tableDataSource.setRowsAndMode(mode, modelRows);
    }

    clearEquipmentIds(event) {
        if (event.target.text == "") {
            this.textboxEquipmentTypeIdGeneral.text = "";
            this.textboxEquipmentTypeIdEquipment.text = "";
        }
    }

    setDispatchSettings() {
        const dispatchControlSettings = getAuthSettings().dispatch_control[0];

        if (dispatchControlSettings.enforce_revenu_cod == 'Y')
            this.textboxRevenueCodeId.required = true;
    }

    textboxBlnumOnBlur(event: BlurEvent) {
        if (!this.mainDataSource.activeRow?.isNull("blnum")) {//&& !this.switchRecurringOrder.checked) {
            let source: DuplicateBOLSource;
            if (this.mainDataSource.mode == DataSourceMode.ADD) {
                source = DuplicateBOLSource.ADD;
            }
            else if (this.mainDataSource.mode == DataSourceMode.UPDATE && this.activeRow.getChangedData()["blnum"] != null) {
                source = DuplicateBOLSource.UPDATE;
            }

            if (source != null) {
                this.checkForDuplicateBOL(source)
                    .then((response) => {
                        if (!response.success)
                            this.textboxBlnum.validationWarning = response.reason;
                    });
            }
        }
    }

    async checkForDuplicateBOL(source: DuplicateBOLSource): Promise<SuccessFail> {
        return await this.checkDuplicateBOL.validate(source, this.saveButton);
    }

    doBeforeExecute(event: DataSourceExecutionEvent) {
        if (event.getAction() === DataSourceAction.ADD) {
            this.stopsTable.rows.forEach(
                row => {
                    log.debug(row);
                    row.saveChanges();
                    log.debug(row.data);
                });
            log.debug("stops datasource length " + this.stopsTable.dataSource.data.length);
        }

        if (event.getAction() === DataSourceAction.ADD || event.getAction() === DataSourceAction.UPDATE)
            this.addBeforePostListeners();
    }


    addBeforePostListeners() {
        if (this.mainDataSource.activeRow != null) {
            const row = this.mainDataSource.activeRow;

            //row.addBeforePostListener(async postEvent => await this.creditValidator.rowUpdated(postEvent));


        }
    }

    async doAfterExecute(event) {
        const tenderId = this.mainDataSource?.activeRow?.get("id", null);

        //TODO: Can we remove some of the profile lookups by setting this here?
        if (this.profile == null) {
            const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: tenderId });
            this.profile = await new ModelEdiorderProfile().searchSingle({ partner_id: ediOrderRow.data["partner_id"], version: ediOrderRow.data["version"] });
        }

        if (event.getAction() === DataSourceAction.ADD || event.getAction() === DataSourceAction.UPDATE) {
            this.activeRow.clearBeforePostListeners();
        }
        if (event.getAction() === DataSourceAction.ADD && this.mainDataSource.activeRow.get("loadboard") === "Y")
            this._displayPostToLoadBoard();
        else if (event.getAction() === DataSourceAction.ADD && this.mainDataSource.activeRow.get("loadboard") === "N")
            this.navigateAfterAdd();
        else if (event.getAction() === DataSourceAction.SEARCH) {
            const overviewLayout = this.layoutOverview as EdiOrderOverview;
            const orderId: string = this.mainDataSource?.activeRow?.get("order_id", null);
            const moveId: string = this.mainDataSource?.activeRow?.get("shipment_id", null);
            const direction: string = this.mainDataSource.activeRow?.get("direction", null);

            (overviewLayout.layoutStopProgress as EdiStopProgress).stopsTable = this.stopsTable;
            (overviewLayout.layoutStopProgress as EdiStopProgress).setStopsData(orderId, moveId, direction);

            const status = this.mainDataSource.activeRow?.get("movement.status", null);
            const onHold = this.mainDataSource.activeRow?.get("on_hold", null);
            this.showHazmatWarning(this.mainDataSource.activeRow?.get("hazmat", "N"));


            this.textboxTenderStatus.visible = "O" == direction;
            this.textboxEdiError.visible = "O" == direction;
            this.textReadyToSend.visible = "O" == direction;
            this.labelTransmitStatus.visible = "O" == direction;
            this.checkboxNoDisplay.visible = "I" == direction;
            this.textboxRbdTimezoneId.visible = !StringUtil.isEmptyString(this.mainDataSource?.activeRow?.get("rbd_timezone_id"));

            const replyAction: string = this.mainDataSource.activeRow?.get("reply_action");
            if (orderId != null || replyAction === "D") {
                this.buttonPurposeSwap.enabled = false;
                this.buttonPurposeSwap.color = "strokeSecondary";
            }

            const purposeType = this.mainDataSource?.activeRow?.get("purpose_type", null);
            this.buttonLinkTender.imageColor = "primary";
            this.buttonLinkTender.enabled = (purposeType == "U" || purposeType == "C") ? true : false;
            this.buttonLinkTender.color = (this.buttonLinkTender.enabled) ? "primary" : "strokeSecondary";
            this.buttonLinkTender.visible = "I" == direction;
            this.buttonPurposeSwap.visible = "I" == direction;
            this.tabCarrierPay.visible = "O" == direction;

            if ("I" == direction) {
                this.tabCarrierPay.visible = false;

                if (this.profile != null) {
                    this.tabFA.visible = this.profile.get("send_func_ack") == "Y";
                }
            }
            else {
                this.tabFA.visible = true;
            }
        }

        this.setTitle();
        this.refreshEllipsisMenu();
        this.getDataHeaderEllipsisActions();
    }

    doAfterModeChange(event: DataSourceModeChangeEvent) {
        this.setCompanySettings(event.newMode);
    }

    enforceActionPermissions(component: any) {
        const isUpdateOrdersAfterBilledDenied = PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after billed");
        const isBilled = this.mainDataSource.activeRow?.get("is_billed", false);

        const isUpdateOrdersAfterDeliveredDenied = PermissionsUtil.isUserDeniedAction("Dispatch.Update orders after delivery");
        const isDelivered = this.mainDataSource.activeRow?.get("status", null) == "D";

        if ((isUpdateOrdersAfterBilledDenied == true && isBilled != null && isBilled == true) ||
            (isUpdateOrdersAfterDeliveredDenied == true && isDelivered != null && isDelivered == true)) {
            const ignoreList = ['layoutImageTable', 'tabsetImages', 'tabImages', 'tableImages', 'buttonMoveDetails', 'tabOverview', 'layoutOverview'];
            if (component != null) {
                component.forEveryChildComponent((child: Component) => child.enabledDuringUpdate = false);
                return;
            }
            this.forEveryChildComponent(comp => {
                if (ignoreList.includes(comp.id))
                    return;
                comp.enabled = false;
                if (comp instanceof Table) {
                    const nestedTable: Table = comp as Table
                    nestedTable.addRowDisplayListener((event: TableRowDisplayEvent) => {
                        const row: TableRow = (event as TableRowDisplayEvent).getTableRow();
                        row.forEveryChildComponent(child => {
                            child.enabledDuringUpdate = false;
                        });
                    });
                }
            });
        }
    }

    doBeforeModeChange(event) {
        this.tabOverview.visible = event.newMode === DataSourceMode.UPDATE || event.newMode === DataSourceMode.NONE;
        this.tabStops.visible = event.newMode !== DataSourceMode.SEARCH;
    }

    private async addStopsToTable() {
        if (this.stopsTable.dataSource.data?.length === 0) {
            await this.addNewStopToTable();
            await this.addNewStopToTable();
            log.debug("stops length " + this.stopsTable.dataSource.data.length)
        }
        this.tabsetAddOrders.expandAll();
    }

    public async addNewStopToTable(): Promise<TableRow> {
        let addRowResult: TableAddRowResult;
        const newRowData = await this.stopsTable._createNewRowData();
        if (newRowData != null) {
            newRowData.set("stop_type", this.stopsTable.data.length > 0 ? "SO" : "PU");
            this.stopsTable.data.push(newRowData);
            addRowResult = this.stopsTable.addRow(newRowData, { mode: TableRowMode.ADD }, { display: true, addToData: true });
        }
        return addRowResult.row;
    }

    commodityCodeChanged() {
        const lookupData = this.textboxCommodityId.getFirstLookupModelData();
        if (lookupData == null)
            return;
        this.textboxCommodity.text = lookupData?.get("descr");

        this.showHazmatWarning(lookupData?.get("is_hazmat"));
        const lmModelRow = new ModelRow(this.textboxHazmatCode.lookupModel, false, { id: lookupData?.get("hazmat_number") });
        this.mainDataSource.activeRow.setLookupModelData("hazmat_code", lmModelRow);
        this.mainDataSource.activeRow.set("hazmat_code", lookupData?.get("hazmat_number"));

        this.mainDataSource.activeRow.set("hazmat", lookupData?.get("hazmat", "N"));
        this.mainDataSource.activeRow.set("temperature_min", lookupData?.get("temperature_min"));
        this.mainDataSource.activeRow.set("temperature_max", lookupData?.get("temperature_max"));
    }

    showHazmatWarning(hazmat: string) {

    }

    textboxAppliesToOnChange(event: ChangeEvent) {
        if (!event.userInitiatedChange)
            return;
        const appliesTo: Textbox = event.target as Textbox;
        const equipType = TableRow.getContainingTableRow(appliesTo).findComponentById("textboxMatchEquipmentTypeId") as Textbox;
        equipType.text = null;
        equipType.updateBoundData(equipType.boundRow, this.mainDataSource.mode);
    }

    textboxMatchEquipmentTypeIdBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.applies_to = "in Y,X,D";
    }

    buttonAddAnotherStopOnClick(event: ClickEvent) {
        this.addNewStopToTable().then(row => row.scrollIntoView());
    }

    buttonAddAnotherStopOnDataDisplay(event: DataDisplayEvent) {
        (event.target as Button).setProps({ ...(event.target as Button).allProps, "color": "primary" });
    }

    //** This is an event handler for stopsTable onRowBeforeSave */
    tableStopBeforeRowSave(event: TableRowBeforeSaveEvent) {
        const tableRow: TableRow = event.target as TableRow;
        const modelRow: ModelRow = tableRow.data;
        const orderStopLayout: EdiStop = event.target["orderStop"] as EdiStop;
        modelRow.set("stop_notes", orderStopLayout.commentsAndRefNbrs.tableStopComments?.data || []);
        modelRow.set("stop_reference_numbers", orderStopLayout.commentsAndRefNbrs.tableStopReferenceNumbers?.data || []);
        const changedData = modelRow.getChangedData();
        if (apptConfirmCheck(changedData, modelRow))
            orderStopLayout.getAppointmentChangedRow();
        else {
            modelRow.set("appointment_change", null);
        }
    }

    textboxRecurringOrderIdBeforeLookupModelSearch(event: LookupModelSearchEvent) {
        event.filter.is_active = "Y";
    }

    /** This is an event handler for the onRowDisplay event of stopsTable.  */
    stopsTableOnRowDisplay(event) {
        const tableRow: TableRow = event.getTableRow();
        // tableRow.canBeDeleted = tableRow.index != 0;
        tableRow.canBeDeleted = false;
        const labelSequence = (tableRow.findComponentById("labelSequence")) as Label;
        labelSequence.caption = (tableRow.index + 1).toString();
        tableRow.findComponentById((comp: any) => {
            if (comp instanceof EdiStop) {
                comp.addLayoutLoadListener(() => {
                    this.enforceActionPermissions(tableRow);
                    this.sourceEdiStop.preventChangeNotifications = true;
                    tableRow["orderStop"] = comp;
                    labelSequence.addClickListener(event => tableRow._showEditLayout());
                    comp.calculateRates = () => this.checkStopsForDistanceChange().then(() => this.layoutRates.calculateRates(false, false));
                    comp.loadingRecurringOrder = this.loadingRecurringOrder
                    comp.initialize(false, tableRow.index, this.sourceViewEdiOrder);
                    if (this.getInvokedFromEdi() || this.loadingRecurringOrder) {
                        comp.setFreeFormVisibility();
                        if (this.loadingRecurringOrder) {
                            comp.doAfterLocationIdChanged()
                            if (tableRow.index > 0)
                                this.loadingRecurringOrder = false;
                        }
                    }
                    comp.displayData(tableRow.data, this.sourceEdiStop.data, tableRow.index);
                    if (tableRow.index == 0) {
                        comp.switchPalletsRequired.displayData(this.sourceViewEdiOrder.activeRow, null, 0);
                        comp.textboxPalletsHowMany.displayData(this.sourceViewEdiOrder.activeRow, null, 0);
                        comp.marginRight = 36;
                    }
                    const commentsTable = comp.commentsAndRefNbrs.tableStopComments;
                    const tableRefNbrs = comp.commentsAndRefNbrs.tableStopReferenceNumbers;
                    this.sourceEdiStop.preventChangeNotifications = false;
                    const txtLocId = comp.textboxLocationId;
                    const txtCases = comp.textboxCases;
                    const originalOrder = this.mainDataSource.activeRow?.get("original_order");
                    txtLocId.enabled = StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id")) && ("Y" === originalOrder);
                    txtCases.enabled = StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id")) && ("Y" === originalOrder);
                    commentsTable.addRowDisplayListener((event: TableRowDisplayEvent) => this.enforceActionPermissions((event as TableRowDisplayEvent).getTableRow()));
                    tableRefNbrs.addRowDisplayListener((event: TableRowDisplayEvent) => this.enforceActionPermissions((event as TableRowDisplayEvent).getTableRow()));
                });
                return true;
            }
            return false;
        });
    }

    private revenueCodeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": newValue,
                "order_type_id": this.mainDataSource.activeRow.get("order_type_id"),
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    private orderTypeChanged(newValue: any) {
        Api.search("lme/dispatch/calculate-order-mode",
            {
                "mode_locked": false,
                "revenue_code_id": this.mainDataSource.activeRow.get("revenue_code_id"),
                "order_type_id": newValue,
                "current_mode": this.mainDataSource.activeRow.get("order_mode")
            }).then(response => {
                const data = response.data[0];
                const newMode = data["mode"];
                this.mainDataSource.activeRow.set("order_mode", newMode);
            });
    }

    displayOrderUsersSlideout() {
        const urs = new UsersResponsibilitySlideoutTrans("lme/datafusion/EdiOrderUsers", this.getEdiOrderUsersSlideoutTitle(), this.getEdiOrderUsersIdFilter(),
            this.getEdiOrderUsersSharedFieldNames(), this.sourceViewEdiOrder, this.sourceResponsibleHist);

        urs.show();
    }

    private getEdiOrderUsersSlideoutTitle(): string {
        const name = this.sourceViewEdiOrder.activeRow?.get("id");
        return "Tender Users" + ((StringUtil.isEmptyString(name) === false) ? (" - " + name) : "");
    }

    private getEdiOrderUsersIdFilter(): any {
        const id = this.sourceViewEdiOrder.activeRow?.get("id");
        if (id != null)
            return { id: id };
        return null;
    }

    private getEdiOrderUsersSharedFieldNames(): string[] {
        return ["operations_user", "agent_payee_id", "entered_user_id"];
    }

    /** This is an event handler for the beforeExecution event of sourceLocation.  It's gross. */
    sourceOrdersAfterGetDataboundValues(row: ModelRow, dataSource: DataSource) {
        if (dataSource.mode === DataSourceMode.SEARCH) {
            //have to set these fields when in search mode because they aren't bound fields for the main layout
            for (const field of this.getEdiOrderUsersSharedFieldNames()) {
                row.set(field, dataSource.searchRow.get(field));
            }
        }
    }

    private _displayPostToLoadBoard() {
        const id = this.mainDataSource.activeRow?.data?.["id"];
        if (id) {
            const layout = Layout.getLayout("lme/dispatch/PostToLoadBoards", {
                maxWidth: 900,
                width: window.innerWidth * .60,
                backgroundColor: "defaultBackground",
                fillHeight: true, paddingBottom: 50
            }) as PostToLoadBoards;

            const erd = new EditRowDecorator({
                title: "Post to Load Boards - Order " + id,
                layout: layout,
                layoutLoadListeners: event => { },
                fillVerticalSpace: true,
                width: 900,
                overlayProps: { greyedBackground: true },
                onSave: () => {
                    erd.multiButton.busy = true;

                    layout.postDataSources()
                        .then(() => layout.slideOut())
                        .finally(() => erd.multiButton.busy = false);
                },
                onClose: (cancelled: boolean) => layout.slideOut(),
                doAfterSlideOut: (decorator: SlideoutDecorator) => this.navigateAfterAdd(),
                doAfterSlideIn: () => {
                    layout.mainDataSource.data[0] = this.mainDataSource.data[0];
                    layout.mainDataSource.rowIndex = 0;
                    layout.mainDataSource.mode = DataSourceMode.UPDATE;
                }
            });
        }
    }

    textboxPnnLoadboardUserIdBeforeLookupModelSearch(event) {
        event.filter.web_user_type = "U";
    }

    public static navigateTo(id: string, newTab = true): void {
        Navigation.navigateTo(viewOrderURL + id, { newTab: newTab });
    }

    navigateAfterAdd() {
        if (this.postOrderAction == null)
            return;
        if (typeof this.postOrderAction === "string") {
            if (!StringUtil.isEmptyString(this.postOrderAction)) {
                let key = null;
                switch (this.postOrderAction) {
                    case this.viewOrderUrl: // view order
                        key = this.mainDataSource?.activeRow?.get("id");
                        Navigation.navigateTo(this.postOrderAction + key, { newTab: false });
                        break;
                    case this.viewOrderListURL:  // view order list
                        const duplicateOrders: Array<String> = this.mainDataSource?.activeRow?.get("duplicate_orders", []);
                        const orderIds = [this.mainDataSource?.activeRow?.get("id"), ...duplicateOrders];
                        const hasFailedOrders = this.mainDataSource?.activeRow?.get("has_failed_orders");
                        Navigation.navigateTo(this.postOrderAction + orderIds.toString(), { newTab: false });
                        break;
                    case this.newOrderURL:  // new order
                        key = this.mainDataSource?.activeRow?.get("id");
                        Navigation.navigateTo(this.postOrderAction, { newTab: false }, { successAdd: true, orderId: key });
                        break;
                    case this._assignCarrierURL:  // assign carrier
                        key = this.mainDataSource?.activeRow?.get("curr_movement_id");
                        Navigation.navigateTo(this._findCarrierURL + key, { newTab: false }, { assignCarrier: true })
                        break;
                    case this.findCarrierURL:  // find carrier
                        key = this.mainDataSource?.activeRow?.get("curr_movement_id");
                        Navigation.navigateTo(this.postOrderAction + key, { newTab: false }, { findCarrier: true });
                        break;
                }
            }
        }
        else if (this.postOrderAction != null)
            this.postOrderAction();
    }

    private postOrderActionButtonClick(event: ClickEvent) {
        const button = event.target as Button;
        button.borderWidth = 1;
        button.color = "primary.reverse";
        button.backgroundColor = "primary";
        button.borderColor = "primary"
    }

    /** This is an event handler for the onClick event of buttonAssignCarrier.  */
    buttonAssignCarrierOnClick(event: ClickEvent) {
        this.postOrderAction = this._assignCarrierURL;
        this.postOrderActionButtonClick(event);
    }

    /** This is an event handler for the onClick event of buttonFindCarrier.  */
    buttonFindCarrierOnClick(event: ClickEvent) {
        this.postOrderAction = this._findCarrierURL;
        this.postOrderActionButtonClick(event);
    }

    /** This is an event handler for the onClick event of buttonEnterNewOrder.  */
    buttonEnterNewOrderOnClick(event: ClickEvent) {
        this.postOrderAction = this.newOrderURL;
        this.postOrderActionButtonClick(event);
    }

    /** This is an event handler for the onClick event of buttonViewOrder.  */
    buttonViewOrderOnClick(event: ClickEvent) {
        this.postOrderAction = viewOrderURL;
        this.postOrderActionButtonClick(event);
    }

    stopTableOnEditLoaded(): (rowDecorator: EditRowDecorator, tableRow: TableRow) => void {
        return (rowDecorator: EditRowDecorator, tableRow: TableRow) => {
            const thisOrderStop = tableRow["orderStop"] as EdiStop;
            const editOrderStop = rowDecorator.layout as EdiStop;
            if (this.sourceViewEdiOrder.isAddingOrUpdating()) {
                editOrderStop.commentsAndRefNbrs.tableStopComments.dataSource.mode = DataSourceMode.UPDATE;
                editOrderStop.commentsAndRefNbrs.tableStopReferenceNumbers.dataSource.mode = DataSourceMode.UPDATE;
            }
            editOrderStop.mainDataSource.activeRow?.set("stop_notes", thisOrderStop?.commentsAndRefNbrs.tableStopComments.data);
            editOrderStop.mainDataSource.activeRow?.set("reference_numbers", thisOrderStop?.commentsAndRefNbrs.tableStopReferenceNumbers.data);
            editOrderStop.initialize(true, tableRow.index, this.sourceViewEdiOrder);
            editOrderStop.slideOutTableRow = tableRow;
            const originalOrder = this.mainDataSource.activeRow?.get("original_order");
            editOrderStop.textboxLocationId.enabled =  StringUtil.isEmptyString(this.mainDataSource.activeRow?.get("order_id")) && ("Y"=== originalOrder);
            editOrderStop.layoutStopShowAsButton.visible = false;
            rowDecorator.onSave = this.stopSlideoutOnSave(rowDecorator, tableRow);
            rowDecorator.btnDelete.visible = false;
            rowDecorator.layout.width = window.innerWidth * .75;
            rowDecorator.labelTitle.caption = "Tender Stop Details - " + this.title;
            rowDecorator.multiButton.dropdownItems = null;
        };
    }

    stopSlideoutOnSave(rowDecorator: EditRowDecorator, tableRow: TableRow): (updatedData: ModelRow | any) => void {
        const orderStop = (rowDecorator.layout as EdiStop);
        const thisOrderStop = tableRow["orderStop"] as EdiStop;
        return async (updatedData: ModelRow | any) => {
            tableRow.saveChangesFromEdit(updatedData);
            if (tableRow.index == 0) {
                this.sourceViewEdiOrder.activeRow.set("pallets_how_many", orderStop.textboxPalletsHowMany.text);
                this.sourceViewEdiOrder.activeRow.set("pallets_required", true === orderStop.switchPalletsRequired.checked ? "Y" : "N");
            }
            orderStop.commentsAndRefNbrs.saveChangesFromEdit(tableRow["orderStop"].commentsAndRefNbrs);
            thisOrderStop.textboxLocationId.displayData(tableRow.data, null, 0);
            thisOrderStop.setLocationTextAfterLocation(thisOrderStop.textboxLocationId.textCombined, tableRow.data, "location_id");
            await thisOrderStop.activeRow?.post();
            thisOrderStop.activeRow.set("initial_appointment_change", null); // reset in scenario if user exits slideout and makes another update on main screen
        };
    }

    viewLoadTenders() {
        //TODO Cleanup
        const orderId = this.mainDataSource.activeRow?.data?.["order_id"];
        log.debug("id", orderId);
        if (orderId) {
            const layout = Layout.getLayout("lme/datafusion/OrderLoadTenderHistory", {
                maxWidth: 900,
                width: window.innerWidth * .60,
                backgroundColor: "defaultBackground",
                fillHeight: true,
                paddingBottom: 50
            }) as OrderLoadTenderHistory;

            new SlideoutDecorator({
                layout: layout,
                title: "Load Tenders - Order " + orderId,
                fillVerticalSpace: true,
                doAfterSlideIn: (decorator: SlideoutDecorator) => layout.mainDataSource.search({ order_id: orderId })
            });

            layout.orderId = orderId;
        }
    }

    set postOrderAction(value: string | (() => void)) {
        this._postOrderAction = value;
    }

    get postOrderAction(): string | (() => void) {
        return this._postOrderAction;
    }

    set viewOrderUrl(value: string) {
        this._viewOrderURL = value;
    }

    get viewOrderUrl(): string {
        return this._viewOrderURL;
    }

    set findCarrierURL(value: string) {
        this._findCarrierURL = value;
    }

    get findCarrierURL(): string {
        return this._findCarrierURL;
    }

    public set assignCarrierURL(value) {
        this._assignCarrierURL = value;
    }

    public get assignCarrierURL() {
        return this._assignCarrierURL;
    }

    /** This is an event handler for the onChange event of switchPostLoadboard.  */
    switchPostLoadboardOnChange(event: ChangeEvent) {
        if (event.userInitiatedChange) {
            const switchPostLoadboard = event.target as Switch;
            const onHold = this.mainDataSource.activeRow.get("on_hold");
            if (switchPostLoadboard != null && switchPostLoadboard.checked && onHold == "Y")
                this.createPostToLoadBoardDialog().then(() => switchPostLoadboard.checked = false);
        }
    }

    createPostToLoadBoardDialog() {
        const dialog = new Dialog({ okVisible: true });
        const lblCaptionLine1 = new Label({
            caption: "Orders on hold may not be posted to the load boards.",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        });
        const lblCaptionLine2 = new Label({
            caption: "Remove the hold to post this order.",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        })
        const pnlCaption = new Panel({ marginRight: 8, verticalAlign: VerticalAlignment.TOP, fillHeight: true, rowBreak: false })
        pnlCaption.add(lblCaptionLine1, lblCaptionLine2);

        const pnlContainer = new Panel({ fillRow: true, maxWidth: 550 });
        pnlContainer.add(pnlCaption);
        dialog.add(pnlContainer);
        return dialog.show();

    }

    createUpdateDeniedDialog() {
        const dialog = new Dialog({ okVisible: true });
        const lblCaptionLine1 = new Label({
            caption: "Order has been billed and you do not have permission to update an order " +
                "after the order has been billed",
            align: HorizontalAlignment.CENTER,
            fontSize: "large"
        });
        const pnlCaption = new Panel({ marginRight: 8, verticalAlign: VerticalAlignment.TOP, fillHeight: true, rowBreak: false });
        pnlCaption.add(lblCaptionLine1);
        const pnlContainer = new Panel({ fillRow: true, maxWidth: 550 });
        pnlContainer.add(pnlCaption);
        dialog.add(pnlContainer);
        return dialog.show();
    }

    buttonOpenClassicOnClick(event: ClickEvent) {
        McLeodClassicIntegration.openClassicScreen("com.tms.client.loadmaster.edi.EntryEdiOrder", this.mainDataSource.activeRow.get("id"));
    }

    public get doAfterAction(): () => void {
        return this._doAfterAction;
    }

    public set doAfterAction(value: () => void) {
        this._doAfterAction = value;
    }

    private executeDoAfterAction() {
        if (this.doAfterAction != null)
            this.doAfterAction();
    }

    createRecurringOrder(event: ClickEvent) {
        const orderId = this.mainDataSource.activeRow?.get("id");
        if (orderId == null) return;

        function makeApiCall(userEnteredRecurringOrderId?: String) {
            Api.post("lme/dispatch/create-recurring-order", { order_id: orderId, recurring_order_id: userEnteredRecurringOrderId }).then(output => {
                const recurringOrderId = output.data[0].recurring_order_id;
                const toastMessage = `Recurring Order ${recurringOrderId} has been created.`;
                Toast.showSuccessToast(toastMessage);
            }).catch(error => {
                for (const message of error.messages) {
                    if (message.includes("already exists in the table")) {
                        Snackbar.showWarningSnackbar("Duplicate recurring order ID");
                        return;
                    }
                }
                Snackbar.showWarningSnackbar(error.messages[0]);
            });
        }

        if (getDispatchControlBoolean("recurring_override", false)) {
            const dialogLayout = Layout.getLayout("lme/dispatch/RecurringOrderPrompt") as RecurringOrderPrompt;
            CommonDialogs.showYesNo(dialogLayout, "Create Recurring Order", { yesButtonCaption: "OK", noButtonCaption: "Cancel" }, false, 150).then(clickedYes => {
                if (clickedYes) {
                    const userEnteredRecurringOrderId: String = dialogLayout.textboxRecurringOrderId.text;
                    makeApiCall(userEnteredRecurringOrderId);
                }
            });
        }
        else {
            makeApiCall();
        }
    }

    voidOrder(event: ClickEvent) {
        const orderId = this.mainDataSource.activeRow?.get("id");
        if (orderId != null) {
            const voidOrderPromptLayout = Layout.getLayout("lme/dispatch/VoidOrderPrompt", {
                backgroundColor: "defaultBackground",
                borderRadius: 4, borderWidth: 1, borderShadow: true, borderColor: "strokeSecondary"
            }) as VoidOrderPrompt

            voidOrderPromptLayout.orderId = orderId
            voidOrderPromptLayout.addLayoutLoadListener(event => {
                voidOrderPromptLayout.onCancel = () => Overlay.hideOverlay(overlay);
                voidOrderPromptLayout.onSave = (success: boolean) => {
                    Overlay.hideOverlay(overlay);
                    if (success) {
                        const toastMessage = `Order ${orderId} has been voided.`;
                        Toast.showSuccessToast(toastMessage);
                        new Promise(resolve => setTimeout(resolve, 2500)).then(() => this.executeDoAfterAction());
                    } else {
                        const snackMessage = `Could not void order ${orderId}`;
                        Snackbar.showWarningSnackbar(snackMessage);
                    }
                };

                const overlay = Overlay.showInOverlay(voidOrderPromptLayout, { closeOnClickOff: false, greyedBackground: true, centered: true });
            });
        }
    }
    imageReorderOnClick(event: ClickEvent) {
        this._rearrangeStops();
    }

    private _duplicateOrder() {
        const orderId: string = this.mainDataSource.activeRow.get("id");
        const dialogLayout = Layout.getLayout("lme/dispatch/DuplicateOrderPrompt") as DuplicateOrderPrompt;
        dialogLayout.addLayoutLoadListener(() => {
            dialogLayout.numbereditor1.value = 1;
        });
        CommonDialogs.showYesNo(dialogLayout, "Duplicate Orders", { yesButtonCaption: "Create Orders", noButtonCaption: "Cancel" }, false, 150).then(clickedYes => {
            if (clickedYes) {
                const howManyOrders = dialogLayout.numbereditor1.value;
                const copyRate: boolean = dialogLayout.checkboxIncludeRate.checked;
                const copyOtherCharges: boolean = dialogLayout.checkboxIncludeOtherCharges.checked;

                this.checkDuplicateBOL.validate(DuplicateBOLSource.DUPLICATE, this.saveButton)
                    .then(result => {
                        if (!result.success) {
                            CommonDialogs.showMessage(result.reason, "", this.checkDuplicateBOL.getDialogProps(true, "Duplicate BOL"))
                        } else {
                            return Api.post("lme/dispatch/duplicate-order", {
                                order_id: orderId,
                                how_many: howManyOrders,
                                fail_on_credit_warning: true,
                                copy_rate: copyRate,
                                copy_other_charges: copyOtherCharges
                            }, null, null).then(response => {
                                const data = response.data[0];
                                const orderIds = data["duplicate_orders"];
                                const hasFailedOrders = data["has_failed_orders"];
                                const failedCredit = data["failed_credit"];
                                // if (hasFailedOrders) {
                                //   this.creditValidator.showCustomerCreditValidationSnackbar("Unable to create all orders. " +
                                //     (failedCredit ? this.textboxCustomerId.text + " has exceeded their credit limit. " : "") +
                                //     (orderIds.length) + " order(s) of " + howManyOrders + " were created.")
                                // }
                                Navigation.navigateTo(this.viewOrderListURL + orderIds.toString(), { newTab: false });
                            });
                        }
                    });
            }
        });
    }

    equipItemTableOnRowDisplay(event) {
        const row: TableRow = event.getTableRow();
        const equipType = (row.findComponentById("textboxMatchEquipmentTypeId")) as Textbox

        if (equipType != null) {
            equipType.onSelectItem = ((textbox, selection) => {
                equipType.boundRow.set("applies_to", (selection as ModelRow).get("applies_to"));
                return undefined;
            })
        }
    }

    public set invokedFromEdi(value: boolean) {
        this._invokedFromEdi = value;
    }
    public getInvokedFromEdi(): boolean {
        return this._invokedFromEdi;
    }

    stopsTableOnContentsChanged(event: TableContentsChangedEvent) {
        if (TableAction.DELETE == event.getAction()) {
            this.checkStopsForDistanceChange().then(() => {
                if (this.layoutRates.checkPickupAndDeliveryEntered()) this.layoutRates.calculateRates(false, false);
            });
        }
        const data = event.getDataSource().data;
        log.info("Found data");
    }

    private validateRespFilteringDuringAdd(event: DataSourceValidationEvent, buttonUsers: Button) {
        if (event.dataSource.activeRow.get("rf_entry_code_required", false) !== true)
            return;
        let rfDataSource: DataSource = null;
        if (event.childDataSources != null) {
            for (const childDataSource of event.childDataSources) {
                if (childDataSource.url === "lme/general/responsible-hist") {
                    rfDataSource = childDataSource;
                    break;
                }
            }
        }
        if (rfDataSource != null) {
            for (const row of rfDataSource.data) {
                if (row.get("responsible_role", null) === "E" &&
                    (row.get("hierarchy_level_1", null) != null || row.get("hierarchy_level_2", null) != null || row.get("hierarchy_level_3", null) != null ||
                        row.get("hierarchy_level_4", null) != null || row.get("hierarchy_level_5", null) != null)) {
                    //an entry row is present, return without adding anything to the validation results
                    return;
                }
            }
        }
        const message = "A responsibility record with role 'Entry' must be specified";
        event.validationResults.push({ isValid: false, validationMessage: message, caption: message, component: buttonUsers });
    }

    private get saveButton(): SaveButton {
        return this.dataHeader?.["saveButton"];
    }

    private displayOrderCreatedToast() {
        const orderId = this.mainDataSource?.activeRow?.get("id", null);
        const toast = Toast.showToast(this.getOrderCreatedToast(orderId, "created", () => this.orderToastLinkOnClick(orderId, toast)), null, { persist: true, targetPanel: McLeodMainPageUtil.getRouterPanel() });
    }

    private orderToastLinkOnClick(orderId: string, toast: Toast) {
        Orders.navigateTo(orderId, false);
        toast.dismiss();
    }

    private getOrderCreatedToast(orderId: string, action: string, doOnLinkClick: () => void): Panel {
        const labelProps = { color: "white", fontSize: "xlarge", rowBreak: false, paddingLeft: 1, paddingRight: 1, marginLeft: 0, marginRight: 0 };
        let labels: Label[] = [];
        if (!StringUtil.isEmptyString(orderId)) {
            labels = [
                new Label({ ...labelProps, caption: "Order " }),
                new Label({ ...labelProps, style: { textDecoration: "underline" }, caption: orderId, onClick: event => doOnLinkClick() }),
                new Label({ ...labelProps, caption: " " + action + "." })
            ];
        }
        else {
            labels = [
                new Label({ ...labelProps, caption: "Order not " + action + "." })
            ];
        }
        return new Panel({ align: HorizontalAlignment.CENTER, fillRow: true, components: labels });
    }

    genericOnDisplay() {

    }

    styleTab(tab: Tab) {
        if (tab.heading != null && tab.headingLabel != null) {
            tab.heading.color = "subtle.light";
            tab.heading.marginBottom = 10;
            tab.headingLabel.setProps({ color: "default", fontBold: true, fillRow: false })
            if (tab["countLabel"] == null) {
                tab["countLabel"] = new Label({
                    fontBold: true, caption: "0", fillRow: true,
                    color: "primary", rowBreak: false, marginLeft: 5,
                    id: "labelErrorCount"
                });
                tab.heading.insert(tab["countLabel"], 1);
            }
        }
    }

    sourceEdiTenderErrorsAfterExecution() {
        const replyErrorsCount = this.sourceEdiMapError.data.filter(error => error.data["transaction_type"] === "R").length;
        const tenderErrorsCount = this.sourceEdiMapError.data.filter(error => error.data["transaction_type"] === "T").length;
        const ignoreMapErrors = this.mainDataSource?.activeRow?.get("ignore_map_errors");
        const direction = this.mainDataSource.activeRow?.get("direction", null);

        if (this.tabErrors != null && this.tabErrors["countLabel"] != null) {
            this.tabErrors["countLabel"].caption = tenderErrorsCount;
            if (tenderErrorsCount > 0) {
                if (ignoreMapErrors == "N") {
                    this.layoutOverview.panelErrorIcons.visible = true;
                    this.layoutOverview.imageInboundTenderError.visible = direction == "I";
                    this.layoutOverview.imageOutboundTenderError.visible = direction == "O";
                } else {
                    this.layoutOverview.panelErrorIcons.visible = false;
                    this.layoutOverview.imageInboundTenderError.visible = false;
                    this.layoutOverview.imageOutboundTenderError.visible = false;
                }
            }
        }
        this.layoutOverview.imageReplyError.visible = replyErrorsCount > 0;

    }

    viewTransmissionStatusQuickInfo(event: DataDisplayEvent) {
        const transmissionLabel = event.target as Label;
        transmissionLabel.tooltipCallback = (base, originatingEvent) => {
            if (transmissionLabel.caption !== "(Not Sent)" && transmissionLabel.caption !== "(Unknown)") {
                const layout = Layout.getLayout("lme/datafusion/TransmissionStatusQuickInfo") as TransmissionStatusQuickInfo;
                const partnerId = this.sourceViewEdiOrder.activeRow.get("partner_id");
                const receiverId = this.sourceViewEdiOrder.activeRow.get("alt_partner_id");
                const direction = this.sourceViewEdiOrder.activeRow.get("direction");
                const version = this.sourceViewEdiOrder.activeRow.get("version");
                const batch = this.sourceViewEdiOrder.activeRow.get("gs06_group_cntlno");
                let input;
                input = { direction: direction, func_version: version, transaction_type: "T", batch: batch };
                input = direction === "O" ? { ...input, func_receiver_id: partnerId, receiver_id: receiverId } : { ...input, func_sender_id: partnerId, sender_id: receiverId }
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiLog !== null) {
                        layout.sourceEdiLog.search(input);
                    }
                });
                return transmissionLabel.showTooltip(layout,
                    {
                        position: transmissionLabel.tooltipPosition == null ? Alignment.BOTTOM : transmissionLabel.tooltipPosition,
                        pointerColor: ThemeCommonPage.quickInfo.borderLeftColor, originatingEvent: originatingEvent
                    },
                    { themeKey: "quickinfo", backgroundColor: "background5", borderRightColor: ThemeCommonPage.quickInfo.borderLeftColor, borderRightWidth: 12, color: null });
            }
        }
    }

    getResultsToastPanel(orderId: string, message: string, action: string): Panel {
        const labelProps = { color: "white", fontSize: "xlarge", rowBreak: false, paddingLeft: 1, paddingRight: 1, marginLeft: 0, marginRight: 0, wrap: false };
        const labelLinkProps = { ...labelProps, style: { textDecoration: "underline" } };

        if (!StringUtil.isEmptyString(orderId)) {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order " }),
                    new Label({ ...labelLinkProps, caption: orderId, onClick: event => Orders.navigateTo(orderId) }),
                    new Label({ ...labelProps, caption: " " + action + ".  " + message })
                ]
            });
        }
        else {
            return new Panel({
                align: HorizontalAlignment.CENTER, fillRow: true,
                components: [
                    new Label({ ...labelProps, caption: "Order not " + action + ".  " + message })
                ]
            });
        }
    }

    public get profile(): RowEdiorderProfile {
        return this._profile;
    }
    public set profile(value: RowEdiorderProfile) {
        this._profile = value;
    }

    public get ediOrderRow(): RowEdiOrder {
        return this._ediOrderRow;
    }
    public set ediOrderRow(value: RowEdiOrder) {
        this._ediOrderRow = value;
    }

    async linkTender(event) {
        const tenderId = this.mainDataSource?.activeRow?.get("id", null);
        const purposeType = this.mainDataSource?.activeRow?.get("purpose_type", null);

        if (tenderId != null) {
            const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: tenderId });
            const profile = await new ModelEdiorderProfile().searchSingle({ partner_id: ediOrderRow.data["partner_id"], version: ediOrderRow.data["version"] });
            let needsReply: boolean = false;

            const dialog = new LinkTenderDialog({ okVisible: false, title: "Missing Order ID On Change Tender" }) as LinkTenderDialog;
            dialog.ediOrderRow = ediOrderRow;
            if (purposeType == "U") {
                needsReply = profile.get("rply_rquired_chang") == "Y" ? true : false;
            }
            else if (purposeType == "C") {
                needsReply = profile.get("rply_rquired_cance") == "Y" ? true : false;
            }

            dialog.show().then(async response => {
                if (response) {
                    log.info("Comparing tender [id: " + tenderId + "]");
                    const compare = new CompareTender();
                    await compare.compareTender(ediOrderRow, needsReply).then(() => {
                        this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                        this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                    });
                }
                else
                    log.info("User chose not to compare tender [id: " + tenderId + "]");
                this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
            });
        }
    }

    async purposeCodeSwap(event: ClickEvent) {
        //get the purpose for the row selected
        const ediOrderRow = await new ModelEdiOrder().searchSingle({ id: this.mainDataSource?.activeRow?.get("id", null) });
        const purpose = ediOrderRow.get("purpose_type");
        const purposeSwap = new PurposeSwap();
        let allowSwap: boolean = true;

        if (purpose === "O") {//change to a change and propmpt for order ID
            const dialog = new PurposeSwapDialog({ okVisible: false, title: "Order ID for Change Tender" });

            dialog.ediOrderRow = ediOrderRow;
            dialog.addUnmountListener(() => {
                if (dialog.wasCancelled) {
                    log.info("User closed the dialog.");
                    allowSwap = false;
                }
            });
            dialog.show(ediOrderRow.get("order_id")).then(async response => {
                if (allowSwap && response) {
                    const orderTextbox = dialog.findComponentById("textOrderId") as Textbox;
                    await purposeSwap.swapOriginalToChange(ediOrderRow, orderTextbox.text).then(() => {
                        this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                        this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                    });
                }
            });

        } else if (purpose === "U") {//it's a change, change to original
            CommonDialogs.showYesNo("This will update the purpose of this tender from a change to an original.  Are you sure you want to do this?", "Change Purpose",
                { titleProps: { imageName: "circleX2", imageColor: "primary.light" }, xVisible: false }).then(clickedYes => {
                    if (clickedYes) {
                        purposeSwap.swapChangeToOriginal(ediOrderRow).then(() => {
                            this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
                            this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
                        });
                    }
                });
        }
        this.buttonPurposeSwap.tooltip = null;
    }

    buildCustomYesNoDialog(titleText: string, prompt: string, yesLabel: string, noLabel: string, customWidth?: number): Dialog {

        const labelCaption = new Label({ caption: prompt, align: HorizontalAlignment.LEFT, fontSize: "medium" });
        const panelPrompt = new Panel({ marginRight: 8, verticalAlign: VerticalAlignment.TOP, fillHeight: true, rowBreak: false });
        panelPrompt.add(labelCaption);
        const width = (customWidth > 0) ? customWidth : 900;
        const panelContainer = new Panel({ fillRow: true });
        const buttonNo = new Button({ caption: noLabel, backgroundColor: "", imageName: "x", rowBreak: false });
        buttonNo.align = HorizontalAlignment.CENTER;
        buttonNo.addClickListener(() => dialog.close(false));
        const buttonYes = new Button({ caption: yesLabel, color: "primary.reverse", backgroundColor: "primary", imageName: "check" });
        buttonYes.addClickListener(() => {
            if (dialog.validateSimple())
                dialog.close(true);
        });
        const panelButtons = new Panel({ align: HorizontalAlignment.CENTER, fillRow: true });
        panelButtons.add(buttonNo);
        panelButtons.add(buttonYes);
        panelContainer.add(panelPrompt);
        panelContainer.add(panelButtons);

        const props = { width: width, titleProps: { imageName: "circleX2", imageColor: "primary.lighter", color: "McLeodWhite" }, panelTitleProps: { backgroundColor: "primary.darker", color: "primary.reverse" } };
        const dialog = CommonDialogs.createDialog(panelContainer, { title: titleText, okVisible: false, ...props });
        return dialog;
    }

    displayPartnerName(event: DataDisplayEvent) {
        const partnerNameLabel = <Label>event.target;
        partnerNameLabel.tooltipCallback = (base, originatingEvent) => {
            const row = this.sourceViewEdiOrder.activeRow;
            const partnerID = row?.get("partner_id");
            const version = row?.get("version");
            const direction = row?.get("direction");
            if (partnerID != null && version != null && direction != null) {
                const layout = Layout.getLayout("lme/datafusion/EDIOrderProfileQuickInfo") as EDIOrderProfileQuickInfo;
                layout.addLayoutLoadListener(() => {
                    if (layout.sourceEdiorderProfile != null) {
                        layout.sourceEdiorderProfile?.search({ partner_id: partnerID, version: version, direction: direction }).then(r => {
                            partnerNameLabel.showTooltip(layout,
                                { position: partnerNameLabel.tooltipPosition == null ? Alignment.BOTTOM : partnerNameLabel.tooltipPosition, anchorToMouse: true, originatingEvent: originatingEvent },
                                { themeKey: "quickInfo", backgroundColor: "background5", color: null });
                        });
                    }
                });
                return layout;
            }
        }
    }

    public get isBrokerageLtl(): boolean {
        return this._isBrokerageLtl;
    }

    public set isBrokerageLtl(value: boolean) {
        this._isBrokerageLtl = value;
        this.tabBrltlFreightItems.visible = value;
        this.tabAccessorials.visible = value;
    }

    setCompanySettings(mode: DataSourceMode) {
        this.isBrokerageLtl = BrltlUtil.isBrokerageLtl();
    }

    toggleEditFields(editable: boolean):void {
        this.textboxCustomerId.enabled = editable;
        this.textboxRevenueCodeId.enabled = editable;
        this.layoutRates.buttonRate.enabled = editable;
        this.layoutRates.checkboxLockMiles.enabled = editable;
        this.buttonUsers.enabled = editable;
        this.checkboxIntercompany.enabled = editable;
        this.switchIgnoreErrors.enabled = editable;
        this.checkboxNoDisplay.enabled = editable;
    }

    sourceBrLtlEdiOrderHdrXFgpAfterExecution(event: DataSourceExecutionEvent){
        this.layoutHandlingReqs.populateHdrs(event.dataSource.data, true);
    }

    async compareTender(ediOrderRow: RowEdiOrder, needsReply: boolean): Promise<any> {
        if(StringUtil.isEmptyString(ediOrderRow.get("order_id"))) {
            CommonDialogs.showError("Order ID is required to compare tender.");
            return null;
        }

        const compare = new CompareTender();
        return await compare.compareTender(ediOrderRow, needsReply).then(() => {
            this.sourceEdiOrder.search({ id: ediOrderRow.get("id") });
            this.sourceViewEdiOrder.search({ id: ediOrderRow.get("id") });
        });
    }

    async hasInvalidChargeCodes(tenderId: string, direction: string): Promise<boolean> {
        let invalidChargeCode = false;
        const otherCharges = await new ModelOtherChargeEdi().search({ order_id: tenderId, direction: direction });
        if (otherCharges) {
            for (const modelRow of otherCharges?.modelRows) {
                if (modelRow.get("charge_id") === null || modelRow.get("charge_id") === undefined) {
                    invalidChargeCode = true;
                    break;
                }
            }
        }
        return invalidChargeCode;
    }
}
